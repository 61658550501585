// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelToken } from 'axios';
// $FlowFixMe
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';
import { BaseModal } from '../../../components/BaseModal';
import { retrieve } from '../../../ducks/entities/callFlow/callFlowOperations';
import LoadingView from './LoadingView';
import type { CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import useAsyncPool from './useAsyncPool';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './ExportCallflowsModal.module.scss';

export type PropsT = {|
  enterpriseId: string,
  callflowIds: { id: string, callFlowType: CallFlowTypeT }[],
  onClose: () => void
|};

const ExportCallflowsModal = (props: PropsT): Element<typeof BaseModal> => {
  const { onClose, enterpriseId, callflowIds } = props;

  // redux
  const dispatch = useDispatch();
  const [numberOfServicesChecked, setNumberOfServicesChecked] = useState(0);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const GRAYED_OUT_COLUMNS = [
    'type',
    'addressNumber',
    'pnNumbers',
    'servicePacks',
    'welcomeMsg',
    'waitingAnnounceMsg',
    'waitingMusicMsg',
    'queueFullMsg',
    'goodByeMsg',
    'noOperatorMsg',
    'closingMsg',
    'exceptionalClosingMsg',
    'starToVoiceMailMessage',
    'waitingMusicPlaylist',
    'waitingAnnouncementPlaylist',
    'ringMsg',
    'callbackAnnouncement',
    'callbackAnnouncementBeforeExiting',
    'callbackAlreadyInQueue',
    'callbackRegistered',
    'callbackWaitAnswer',
    'callbackMenuAccept',
    'callbackMenuKeep',
    'callbackMenuModify',
    'callbackMenuDecline',
    'callbackMenuReenter',
    'callbackKept',
    'callbackCancelled',
    'callbackNumber',
    'callbackWrongNumber'
  ];
  const GRAYED_OUT_COLOR = '#b0b0b1';

  let checked = 0;
  const fetchServiceData = service =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      const data = await dispatch(
        retrieve(enterpriseId, service.id, service.callFlowType, CancelToken.source().token, false)
      );
      if (data) {
        checked += 1;
        setNumberOfServicesChecked(checked);
        resolve(data);
        return;
      }
      resolve();
    });

  const [callflowServices, isLoading] = useAsyncPool(callflowIds, fetchServiceData);

  const handleExportCallflows = async services => {
    const { cancelToken } = CancelToken.source();
    const START_ROW_INDEX = 0;
    const HEADER_ROW_INDEX = 1;

    const payload = {
      totalCount: services.length,
      results: services
    };
    const response = await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/services/export`,
      cancelToken,
      data: payload,
      headers: createCsrfHeader(currentUser)
    });
    if (response) {
      const startRow = response.data[START_ROW_INDEX].map(data => ({
        type: String,
        value: data
      }));
      const headerRow = response.data[HEADER_ROW_INDEX].map(data => ({
        type: String,
        value: data,
        fontWeight: 'bold'
      }));
      const grayedOutIndices = headerRow
        .map((header, index) => (GRAYED_OUT_COLUMNS.includes(header.value) ? index : -1))
        .filter(index => index !== -1);
      const res = response.data.slice(2);
      const rows = res.map(data =>
        data.map((d, index) => ({
          type: String,
          value: d,
          backgroundColor: grayedOutIndices.includes(index) ? GRAYED_OUT_COLOR : undefined
        }))
      );
      const ouputData = [startRow, headerRow, ...rows];
      await writeXlsxFile(ouputData, {
        fileName: `callflows_export_${moment().format('YYYYMMDD')}.xlsx`
      });
    }
  };

  // fetchCallflowServices(callflowIds)
  useEffect(() => {
    if (!isLoading) {
      handleExportCallflows(callflowServices || []).then(() => {
        onClose();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <BaseModal modalStyles={[styles.modal]} onClose={onClose} onClickOutsideClose>
      <LoadingView
        numberOfServicesToCheck={callflowIds.length}
        numberOfServicesChecked={numberOfServicesChecked}
      />
    </BaseModal>
  );
};

export default ExportCallflowsModal;
