// @flow
import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import CallflowDetails from '../components/view/CallflowDetails';
import CallflowDetailsColumn from '../components/view/CallflowDetailsColumn';
import { getNameToShow } from '../../../ducks/entities/callFlow/callFlowSelectors';
import CallflowItem from '../components/view/children/CallflowItem';
import CallflowList from '../components/view/children/CallflowList';
import TooltipExtension from '../../../components/TooltipExtension';
import UnattachedStepRow from '../components/edit/children/welcomeAttendantUnattachedSteps/UnattachedStepRow';
import { getUnattachedSteps } from '../callFlowGrid/details/welcomeAttendant/WelcomeAttendantUtils';
import useEnterprise from '../../useEnterprise';
import { getGroupDisplayPolicies } from '../components/edit/CallflowPropertyUtils';
import CallflowAudioProperty from '../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../components/edit/children/audio/AudioFieldConverter';
import LinkButton from '../../../components/Button/LinkButton';
import { goTo } from '../../../navigationOperations';

export type PropsT = {|
  callflowId: string,
  onClick: (SyntheticEvent<HTMLButtonElement>) => void
|};

export const ViewCallflowContent = (props: PropsT): null | Element<typeof CallflowDetails> => {
  const { callflowId, onClick } = props;

  const { t } = useTranslation();
  const callflow = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const currentUser = useSelector(state => state.currentUser);
  const { enterprise } = useEnterprise(callflow.enterpriseId);
  const dispatch = useDispatch();

  if (!callflow) {
    return null;
  }

  const pnNumbers =
    callflow.pnNumbers && Array.isArray(callflow.pnNumbers) && callflow.pnNumbers.length > 0
      ? callflow.pnNumbers
      : [t('callflows.viewCallflowContent.noPnNumbers')];

  const hiddenDirectory: string =
    callflow.hiddenDirectory !== undefined && typeof callflow.hiddenDirectory === 'boolean'
      ? callflow.hiddenDirectory.toString()
      : '';

  const showActiveSelector: boolean = callflow && R.path(['active'], callflow) !== undefined;

  const unattachedSteps =
    callflow && callflow.type === 'WELCOME_ATTENDANT' ? getUnattachedSteps(callflow) : [];

  const {
    groupDisplayedNumberPolicy,
    groupDisplayedLabelsTerminalsPolicy
  } = getGroupDisplayPolicies(enterprise, callflow);

  const callflowAudioFieldConverter = new AudioFieldConverter(
    callflow.enterpriseId,
    callflow.id,
    'acds',
    'AnnoucementService'
  );

  return (
    <CallflowDetails nodeId="callflow-page" icon={null} title="" active onClick={onClick}>
      <CallflowDetailsColumn id="cf-column-1">
        {showActiveSelector ? (
          <CallflowItem
            id="callflow-active-status"
            title={t('callflows.viewCallflowContent.active')}
            value={
              callflow.active
                ? t('callflows.viewCallflowContent.activeTrue')
                : t('callflows.viewCallflowContent.activeFalse')
            }
          />
        ) : null}
        {(callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD') &&
        !(currentUser.featureFlags || []).includes('FEATURE-HIDE-CALLFLOW-INACTIVEGROUPMESSAGE') &&
        (callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD') ? (
          <CallflowAudioProperty
            title={t('callflows.viewCallflowContent.inactiveGroupMessage')}
            isActive={!callflow.active}
            audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
              'InactiveGroupMessage',
              'AnnoucementService_InactiveGroupMessage.mp3'
            )}
          />
        ) : null}
        {callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD' ? (
          <CallflowItem
            id="callflow-allowmemberseditfwd-status"
            title={t('callflows.editCallflowContent.allowMembersEditFwd')}
            value={callflow.allowMembersEditFwd ? t('generic.yes') : t('generic.no')}
          />
        ) : null}
        <CallflowItem
          id="callflow-name"
          title={t('callflows.viewCallflowContent.name')}
          value={getNameToShow(callflow)}
        />
        {callflow.type === 'ACD_CUSTOMER_SERVICE' || callflow.type === 'ACD_SWITCHBOARD' ? (
          <CallflowItem
            id="callflow-groupFreeText"
            title={t('callflows.viewCallflowContent.groupFreeTextTitle')}
            value={callflow.groupFreeText}
          />
        ) : null}
        <CallflowList
          id="callflow-phoneNumbers"
          title={t('callflows.viewCallflowContent.phoneNumbers')}
          values={pnNumbers}
        />
        <CallflowItem
          id="callflow-addressNumber"
          title={t('callflows.viewCallflowContent.addressNumber')}
          value={callflow.addressNumber}
        />
        {callflow &&
          (callflow.type === 'ACD_SWITCHBOARD' || callflow.type === 'ACD_CUSTOMER_SERVICE') && (
            <CallflowItem
              id="supervisors_link"
              title={t('callflows.viewCallflowContent.acdSupervisorsTitle')}
              active
              value={
                <LinkButton
                  id="supervisors_link"
                  label={t('callflows.viewCallflowContent.acdSupervisorsLink')}
                  onClickAction={() =>
                    dispatch(
                      goTo({
                        pathname: `/enterprises/${callflow.enterpriseId}/callflows/acd-supervisors`,
                        state: {
                          acdId: callflow.id
                        }
                      })
                    )
                  }
                />
              }
            />
          )}
      </CallflowDetailsColumn>
      <CallflowDetailsColumn id="cf-column-2">
        {callflow.priority !== undefined ? (
          <CallflowItem
            id="callflow-groupPriority"
            title={t('callflows.viewCallflowContent.groupPriority')}
            value={callflow.priority === 0 ? t('groupPriority.notInUse') : callflow.priority}
            tooltip={
              <TooltipExtension
                title={t('groupPriority.title')}
                text={t('groupPriority.tooltip')}
                subText={t('groupPriority.tooltip2')}
              />
            }
          />
        ) : null}
        {groupDisplayedNumberPolicy ? (
          <CallflowItem
            id="callflow-groupDisplayedNumber"
            title={t('callflows.viewCallflowContent.groupDisplayedNumber')}
            value={t(`callflows.numberToDisplay.${groupDisplayedNumberPolicy}`)}
            tooltip={
              <TooltipExtension
                title={t('callflows.viewCallflowContent.groupDisplayedNumber')}
                text={parse(
                  `${t(
                    'callflows.viewCallflowContent.groupDisplayedNumberTooltip1'
                  )}<br><br><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedNumberTooltip2'
                  )}</li><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedNumberTooltip3'
                  )}</li><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedNumberTooltip4'
                  )}</li>`
                )}
              />
            }
          />
        ) : null}
        {groupDisplayedLabelsTerminalsPolicy ? (
          <CallflowItem
            id="callflow-groupDisplayedLabels"
            title={t('callflows.viewCallflowContent.groupDisplayedLabels')}
            value={groupDisplayedLabelsTerminalsPolicy.map(label => (
              <div key={`callflows.labelsToDisplay.${label}`}>
                {t(`callflows.labelsToDisplay.${label}`)}
              </div>
            ))}
            tooltip={
              <TooltipExtension
                title={t('callflows.viewCallflowContent.groupDisplayedLabels')}
                text={parse(
                  `${t(
                    'callflows.viewCallflowContent.groupDisplayedLabelsTooltip1'
                  )}<br><br><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedLabelsTooltip2'
                  )}</li><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedLabelsTooltip3'
                  )}</li><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedLabelsTooltip4'
                  )}</li><li>${t(
                    'callflows.viewCallflowContent.groupDisplayedLabelsTooltip5'
                  )}</li>`
                )}
              />
            }
          />
        ) : null}
        <CallflowItem
          id="callflow-hiddenDirectory"
          title={t('callflows.viewCallflowContent.phonebookVisibility')}
          value={t(`callflows.viewCallflowContent.hiddenDirectory.${hiddenDirectory}`)}
          tooltip={
            <TooltipExtension
              title={t('callflows.viewCallflowContent.phonebookVisibility')}
              text={t('callflows.viewCallflowContent.phonebookVisibilityTooltip')}
            />
          }
        />
        {callflow && callflow.type === 'WELCOME_ATTENDANT' && (
          <CallflowList
            id="callflow-unattached"
            title={t('callflows.viewCallflowContent.unattachedTitle')}
            tooltip={t('callflows.viewCallflowContent.unattachedTooltip')}
            values={
              unattachedSteps && unattachedSteps.length > 0
                ? unattachedSteps.map(cmd => <UnattachedStepRow name={cmd.name} type={cmd.type} />)
                : [t('callflows.viewCallflowContent.unattachedNoResults')]
            }
          />
        )}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default ViewCallflowContent;
