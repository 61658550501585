/* eslint-disable flowtype/no-weak-types */
// @flow

import Moment from 'moment';

export const OFFICE_HOURS = {
  startTime: '08:00',
  endTime: '16:00'
};

export const filterVisibleDates = (dates: any) => {
  const startDate = Moment().format('YYYY-MM-DD');
  const endDate = Moment()
    .add(1, 'y')
    .format('YYYY-MM-DD');

  const result = dates.filter(dateEntry => {
    return (
      (!dateEntry.repeatsYearly && !dateEntry.repeatsYearlyAtHoliday) ||
      (dateEntry.date >= startDate && dateEntry.date <= endDate)
    );
  });

  result.sort((a, b) => {
    if (a.date > b.date) return 1;
    if (a.date < b.date) return -1;
    return 0;
  });

  return result;
};

/* TODO: fixme and use me, I dont work in all possible cases
export const mergeAndRemoveOverLapping = (slots: any) => {
  const absoluteTimeSlotEntities = slots.map(slot => {
    return {
      id: uuid(),
      startTime: `2014-02-27 ${slot.startTime}`,
      endTime: `2014-02-27 ${slot.endTime}`,
      active: true,
      presenceState: 'AWAY',
      newSlot: false
    };
  });

  const merged = removeContainedAndMergeOverlappingAbsoluteTimeSlots(absoluteTimeSlotEntities);

  const result = merged.map(slot => {
    return {
      startTime: Moment(slot.startTime).format('HH:mm'),
      endTime: Moment(slot.endTime).format('HH:mm')
    };
  });

  return result;
};
*/

export const findHolidayForDate = (dateStr: string, holidayData: []) => {
  let found = null;
  if (!holidayData) {
    return null;
  }
  holidayData.some(hd => {
    if (dateStr.startsWith(hd.date)) {
      found = hd;
    }
    return found !== null;
  });
  return found;
};

export const findTitleForDate = (dateStr: string, holidayData: []): string => {
  const hd = findHolidayForDate(dateStr, holidayData);
  return hd !== null ? hd.name : '';
};
