// @flow

import React, { type Element, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Input from '@design-system/component-library/src/components/Input';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { Button } from '@design-system/component-library';
import { useTranslation } from 'react-i18next';
import { findTitleForDate, filterVisibleDates } from './calendarTemplateUtil';
import CreateButton from '../../../components/Button/CreateButton';
import {
  goToCreateCalendarTemplate,
  goToEditCalendarTemplate
} from '../../../navigationOperations';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import GenericError from '../../../components/Error/GenericError';
import DateTable from './DateTable';

import styles from './CalendarTemplates.module.scss';

export type PropsT = {|
  enterpriseId: string
|};

const CalendarTemplates = (props: PropsT): Element<'div'> => {
  const { enterpriseId } = props;

  // State
  const [calendars, setCalendars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchData = async url => {
    const response = await axios.get(url);
    return response.data;
  };

  const fetchCalendarTemplates = async () => {
    try {
      const holidays = await fetchData(`/api/v1/config/bank_holidays`);
      let calendarData = await fetchData(`/api/v1/enterprises/${enterpriseId}/calendartemplates`);

      calendarData = calendarData.filter(c => c.enterpriseId !== '0'); // no need to list default calendars in this view

      const calendarPromises = calendarData.map(async calendar => {
        const singleCalendarData = await fetchData(
          `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendar.id}`
        );
        return {
          ...calendar,
          dates: filterVisibleDates(
            singleCalendarData.effectiveDates.map(s => ({
              ...s,
              title: findTitleForDate(s.date, holidays)
            }))
          )
        };
      });

      const updatedCalendars = await Promise.all(calendarPromises);
      setCalendars(updatedCalendars);
    } catch (err) {
      console.error(err);
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCalendarTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseId]);

  const header = (
    <div className={styles['header-container']}>
      <h4 className={styles.title} data-cy="calendar-templates-title">
        {t('calendars.templates.title', {
          numberOfCalendars: calendars.length
        })}
      </h4>
      <div className={styles['header-area']}>
        <div className={styles.description}>{t('calendars.templates.helpText')}</div>
        <div>
          <CreateButton
            id="create-template-button"
            text={t('calendars.createTemplateButton')}
            onClickAction={() => dispatch(goToCreateCalendarTemplate(enterpriseId))}
            containerStyle={styles['create-button']}
            showTextAlways
          />
        </div>
      </div>
    </div>
  );

  const handleSearchChange = (search: string) => {
    setSearchText(search);
  };

  return (
    <div className={styles.container}>
      {header}
      <Input
        id="search-calendar"
        className={styles['search-field']}
        placeholder={t('calendars.searchPlaceholder')}
        onValueChange={e => handleSearchChange(e.target.value)}
        type="search"
        optional
        i18n_input_optionalText=""
        maxlength={107}
      />
      <div className={styles['content-container']}>
        {error && <GenericError message={t('efax.efaxListFailedMsg')} />}
        {isLoading ? (
          <CenterHorizontally>
            <LoadingSpinner />
          </CenterHorizontally>
        ) : (
          <Accordion className={styles.header}>
            {calendars &&
              calendars
                .filter(
                  cal => !searchText || cal.name.toUpperCase().includes(searchText.toUpperCase())
                )
                .map(
                  cal =>
                    cal && (
                      <AccordionItem
                        id={`calendar-${cal.id}`}
                        key={`calendar-${cal.id}`}
                        heading={cal.name}
                      >
                        <DateTable editable={false} dates={cal.dates} templateName="" />
                        <div className={styles['edit-button']}>
                          <Button
                            onClick={() => {
                              dispatch(goToEditCalendarTemplate(enterpriseId, cal.id));
                            }}
                          >
                            {t('calendars.modifyCalendarButton')}
                          </Button>
                        </div>
                      </AccordionItem>
                    )
                )}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default CalendarTemplates;
