// @flow

import React, { type Element, type ComponentType, useState } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useTranslation } from 'react-i18next';
import { IdTreeCombobox } from '../../../components/InputComponent/ReduxFormField';
import DepartmentComboboxItem from './DepartmentComboboxItem';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department/index';
import type { IdTreeT } from '../../../ducks/entities/department/departmentTypes';
import CancelButton from '../../../components/Button/CancelButton';
import ActionButton from '../../../components/Button/ActionButton';
import styles from './DepartmentDelete.module.scss';

export type PropsT = {|
  department: DepartmentStateEntityT,
  departmentIdTree: IdTreeT,
  numberOfDepartmentUsers: number,
  isConfirmDisabled: boolean,
  onClickDelete: boolean => *,
  onClickPeopleLink: () => *,
  showRemoveContent: boolean => *,
  isDeleting: boolean,
  targetDepartmentSelected: boolean
|};

const DepartmentDelete = ({
  department,
  departmentIdTree,
  numberOfDepartmentUsers,
  isConfirmDisabled,
  onClickDelete,
  onClickPeopleLink,
  showRemoveContent,
  isDeleting,
  targetDepartmentSelected
}: PropsT): Element<'div'> => {
  const [movePeopleBeforeDelete, setMovePeopleBeforeDelete] = useState(true);
  const { t } = useTranslation();

  return (
    <div id="remove-department-section">
      <div className={styles.header}>{t('enterprise.departmentDetails.removeDepartment')}</div>
      <div>
        {`${t('enterprise.departmentDetails.removingDepartment')} ${department.name}.`}
        {t('enterprise.departmentDetails.totalOf')}
        <button
          id="department-people-link"
          className={styles['department-people-link']}
          onClick={onClickPeopleLink}
        >
          {`${numberOfDepartmentUsers} ${t('enterprise.departmentDetails.people')}.`}
        </button>
      </div>
      {numberOfDepartmentUsers > 0 && (
        <div>
          <div>{t('enterprise.departmentDetails.movePeople')}</div>
          <div id="department-delete-mode-selector">
            <RadioGroup value={movePeopleBeforeDelete ? 'true' : 'false'}>
              <Radio
                id="move-people-from-department-option"
                name="department-option"
                onChange={() => setMovePeopleBeforeDelete(true)}
                className={styles['department-option']}
                label={t('enterprise.departmentDetails.movePeopleOption')}
                value="true"
              />
              <Radio
                id="remove-people-from-department-option"
                name="department-option"
                onChange={() => setMovePeopleBeforeDelete(false)}
                label={t('enterprise.departmentDetails.doNotMovePeopleOption')}
                value="false"
              />
            </RadioGroup>
            {movePeopleBeforeDelete && departmentIdTree.length > 0 && (
              <div className={styles['department-selection']}>
                <Field
                  id="department-selection"
                  label={t('enterprise.departmentDetails.selectDepartmentLabel')}
                  name="destinationDepartment"
                  options={departmentIdTree}
                  // $FlowFixMe
                  component={props => (
                    <IdTreeCombobox
                      style={{ marginLeft: '40px', width: '300px' }}
                      {...props}
                      hideKeyAndChildren={department.id}
                      renderItem={p => <DepartmentComboboxItem {...p} />}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles['buttons-row']}>
        <ActionButton
          id="delete-department-confirm-button"
          label={t('enterprise.departmentDetails.confirmButton')}
          onClickAction={() => {
            if (!isConfirmDisabled) {
              onClickDelete(movePeopleBeforeDelete);
            }
          }}
          className={styles.buttons}
          disabled={
            isConfirmDisabled ||
            (numberOfDepartmentUsers > 0 && movePeopleBeforeDelete && !targetDepartmentSelected)
          }
          loading={isDeleting}
        />
        <CancelButton
          id="delete-department-cancel-button"
          label={t('enterprise.departmentDetails.cancelButton')}
          onClickAction={() => showRemoveContent(false)}
          className={classNames(styles.buttons, styles['cancel-button'])}
        />
      </div>
    </div>
  );
};

export default (DepartmentDelete: ComponentType<PropsT>);
