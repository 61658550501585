// @flow

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, reset } from 'redux-form';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import Dismiss from '../Button/Dismiss';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import Search from '../Search/Search';
import DirectoryItem from './DirectoryItem';
import styles from './DirectorySelectorFormSmall.module.scss';

type OwnPropsT = {
  searchResults: Array<DirectoryStateEntityT>,
  preselection?: Array<DirectoryStateEntityT>,
  onSearchTermChange: (searchTerm: string) => void,
  onSearchTermClear: () => void,
  onSelectionChange: (Array<DirectoryStateEntityT>) => void,
  errorMessage: string,
  isLoadingSearchResults?: boolean,
  maxSelected: number,
  hideInitialSelection: boolean
};

type StatePropsT = {};

type DispatchPropsT = {
  reset: () => void
};

export type PropsT = {
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
};

type StateT = {
  selectedItems: Array<DirectoryStateEntityT>,
  showMoreOpen: boolean
};

export const MAX_SMALL_SCREEN_SELECTED_USERS_COUNT = 5;

export class DirectorySelectorFormSmall extends Component<PropsT, StateT> {
  static defaultProps = {
    isLoadingSearchResults: false,
    maxSelected: Infinity
  };

  constructor(props: PropsT) {
    super(props);
    this.handleSearchInputChanged = this.handleSearchInputChanged.bind(this);
    this.handleSearchInputCleared = this.handleSearchInputCleared.bind(this);
    this.renderResultItem = this.renderResultItem.bind(this);
    this.renderSelectedList = this.renderSelectedList.bind(this);
    this.handleResultItemClicked = this.handleResultItemClicked.bind(this);
    this.handleSelectedItemDismissed = this.handleSelectedItemDismissed.bind(this);
  }

  state = {
    selectedItems: this.props.preselection ? this.props.preselection : [],
    showMoreOpen: false
  };

  handleSearchInputChanged: () => void;

  handleSearchInputChanged(searchTerm: string) {
    const { onSearchTermChange } = this.props;
    onSearchTermChange(searchTerm);
  }

  handleSearchInputCleared: () => void;

  handleSearchInputCleared() {
    const { onSearchTermClear, reset } = this.props; // eslint-disable-line no-shadow
    reset();
    onSearchTermClear();
  }

  handleResultItemClicked: (item: DirectoryStateEntityT) => void;

  handleResultItemClicked(item: DirectoryStateEntityT) {
    this.setState(({ selectedItems }) => {
      const { onSelectionChange, maxSelected } = this.props;
      const newSelectedItems = R.uniq([...selectedItems, item]);

      if (newSelectedItems.length > maxSelected && maxSelected !== -1) {
        return { selectedItems };
      }

      if (!R.equals(newSelectedItems, selectedItems)) {
        onSelectionChange(newSelectedItems);
      }
      return {
        selectedItems: newSelectedItems
      };
    });
  }

  handleSelectedItemDismissed: (item: DirectoryStateEntityT) => void;

  handleSelectedItemDismissed(item: DirectoryStateEntityT) {
    const { onSelectionChange } = this.props;
    this.setState(({ selectedItems }) => {
      const newSelectedItems = R.reject(({ id }) => id === item.id, selectedItems);
      onSelectionChange(newSelectedItems);
      return {
        selectedItems: newSelectedItems
      };
    });
  }

  renderResultItem: (*) => Element<'li'> | null;

  renderResultItem(item: DirectoryStateEntityT) {
    return this.state.selectedItems.some(
      selectedItem =>
        // $FlowFixMe
        selectedItem.addressNumber === item.publicInfo.addressNumber ||
        (selectedItem.publicInfo &&
          selectedItem.publicInfo.addressNumber === item.publicInfo.addressNumber)
    ) ? null : (
      // eslint-disable-next-line
      <li key={item.id} onClick={() => this.handleResultItemClicked(item)}>
        <DirectoryItem className={styles['result-user-item']} directoryOrInteralUser={item} />
      </li>
    );
  }

  renderSelectedItem: DirectoryStateEntityT => Element<'div'>;

  renderSelectedItem(item: DirectoryStateEntityT): Element<'div'> {
    return (
      <div className={styles['selected-item']} key={item.id}>
        <DirectoryItem className={styles['selected-user-item']} directoryOrInteralUser={item} />
        <Dismiss
          id={`dismiss-selected-user-${item.id}`}
          onClose={() => this.handleSelectedItemDismissed(item)}
          dismissStyle={styles.dismiss}
        />
      </div>
    );
  }

  renderSelectedList: () => Element<'div'>;

  renderSelectedList(): Element<'div'> {
    const { t, maxSelected } = this.props;
    const { selectedItems, showMoreOpen } = this.state;
    const showMore = selectedItems.length > MAX_SMALL_SCREEN_SELECTED_USERS_COUNT && !showMoreOpen;
    const showLess = selectedItems.length > MAX_SMALL_SCREEN_SELECTED_USERS_COUNT && showMoreOpen;
    return (
      <div className={styles['selected-container']}>
        <div className={styles['selected-description']}>
          {selectedItems.length > 0 && (
            <span>
              {maxSelected === Infinity || maxSelected === -1
                ? t('forwardingDetails.userSelector.selectedUsersDescriptionWithCount', {
                    count: selectedItems.length
                  })
                : t('forwardingDetails.userSelector.selectedUsersDescriptionWithCountAndMax', {
                    count: selectedItems.length,
                    max: maxSelected
                  })}
            </span>
          )}
        </div>
        <div className={styles['selected-items']}>
          {selectedItems
            .filter((_, index) => index < MAX_SMALL_SCREEN_SELECTED_USERS_COUNT || showMoreOpen)
            .map(item => this.renderSelectedItem(item))}
          {showMore && (
            <button
              className={styles['show-more-less-link-button']}
              onClick={() => this.setState({ showMoreOpen: true })}
            >
              {t('forwardingDetails.userSelector.showAll')}
            </button>
          )}
          {showLess && (
            <button
              className={styles['show-more-less-link-button']}
              onClick={() => this.setState({ showMoreOpen: false })}
            >
              {t('forwardingDetails.userSelector.showLess')}
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { searchResults, t, errorMessage, isLoadingSearchResults } = this.props;
    const { selectedItems } = this.state;

    return (
      <div className={styles.container}>
        {errorMessage && (
          <div data-cy="user-selector-error" className={styles['specific-validation-error']}>
            {errorMessage}
          </div>
        )}
        {/* $FlowFixMe: Search component's types should be completely rewritten again */}
        <Search
          id="UserSelectorInput"
          inputLabel={t('forwardingDetails.userSelector.searchInputPlaceholderUsers')}
          searchClassName={styles['search-content']}
          searchInputContainerClassName={styles['search-input-container']}
          resultsClassName={styles['search-results']}
          searchResults={searchResults}
          renderResultItem={this.renderResultItem}
          renderSelectedList={this.renderSelectedList}
          isSectionInEditMode
          selectedItems={selectedItems}
          searchInputChanged={this.handleSearchInputChanged}
          searchInputCleared={this.handleSearchInputCleared}
          maxSearchResults={Infinity}
          isLoadingSearchResults={isLoadingSearchResults}
          searchResultsOnlyWithSearchTerm
        />
      </div>
    );
  }
}

// $FlowFixMe
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('directorySelector'))
});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'directorySelector' })
)(DirectorySelectorFormSmall);
