// @flow

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import { FormSection, reduxForm, formValueSelector, Field } from 'redux-form';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import parse from 'html-react-parser';
import { selectors as userSelect } from '../../../ducks/entities/user';
import type { KeyValuePairT, StoreStateT } from '../../../commonTypes';
import type {
  DepartmentStateEntityT,
  IdTreeT
} from '../../../ducks/entities/department/departmentTypes';
import {
  operations as departmentOps,
  selectors as selectDepartments
} from '../../../ducks/entities/department/index';
import { INPUT_DEBOUNCE_DURATION } from '../../../constants';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import type { ActiveTabT } from '../../../ducks/ui/department/departmentUiTypes';
import DepartmentDelete from './DepartmentDelete';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import ActionButton from '../../../components/Button/ActionButton';
import fieldValidators from '../../../fieldValidators';
import FormInput from '../../../components/InputComponent/FormInput';
import CancelButton from '../../../components/Button/CancelButton';
import { FormIdTreeCombobox } from '../../../components/InputComponent/FormIdTreeCombobox';
import Tooltip from '../../../components/Tooltip';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import styles from './DepartmentModifyView.module.scss';

export const NO_VALUE_FIELD_ID = '@NULL';
const SHOW_DEPARTMENT_PARENT_SELECTION = false;

export type OwnPropsT = {
  enterpriseId: string, // eslint-disable-line react/no-unused-prop-types
  onDeleteDepartment: (shouldMoveUsers: boolean) => void,
  noDebounce?: boolean,
  department: DepartmentStateEntityT,
  setActiveTab: ActiveTabT => void
};

type StatePropsT = {
  departmentName: string,
  parentDepartment: KeyValuePairT<>,
  departmentUsers: InternalUserStateEntityT[],
  destinationDepartment: string,
  hasSubDepartments: boolean,
  departmentIdTree: IdTreeT,
  initialValues: {
    modify: {
      nameField: string,
      parentDepartment: KeyValuePairT<>
    }
  },
  currentUser: CurrentUserStateT
};

type DispatchPropsT = {
  updateDepartment: typeof departmentOps.updateDepartment
};

export type ViewModeT = 'view' | 'edit';

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {|
  removeActive: boolean,
  cannotRemoveError: boolean,
  isDeleting: boolean,
  viewMode: ViewModeT
|};

export class DepartmentModifyView extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      cannotRemoveError: false,
      removeActive: false,
      isDeleting: false,
      viewMode: 'view'
    };
    this.validateDepartmentName = this.validateDepartmentName.bind(this);
    this.isSaveDepartmentButtonActive = this.isSaveDepartmentButtonActive.bind(this);
    this.showRemoveContent = this.showRemoveContent.bind(this);
    this.handleUpdateDepartment = this.props.noDebounce
      ? this.handleUpdateDepartment.bind(this)
      : debounce(this.handleUpdateDepartment.bind(this), INPUT_DEBOUNCE_DURATION);
    this.handleOnDelete = this.handleOnDelete.bind(this);
  }

  isSaveDepartmentButtonActive: () => boolean;

  isSaveDepartmentButtonActive() {
    const { department, departmentName = '' } = this.props;
    const currentDepartmentName = department ? department.name : '';
    const trimmedUpdatedDepartmentName = departmentName.trim();

    return (
      trimmedUpdatedDepartmentName.length > 0 &&
      currentDepartmentName !== trimmedUpdatedDepartmentName &&
      fieldValidators.departmentNameFieldValidator(departmentName, '') === undefined
    );
  }

  validateDepartmentName: string => ?string;

  validateDepartmentName(value: string): ?string {
    return fieldValidators.departmentNameFieldValidator(
      value,
      this.props.t('generic.validators.departmentName')
    );
  }

  handleUpdateDepartment: () => void;

  handleUpdateDepartment() {
    const {
      department,
      updateDepartment,
      departmentName = '',
      parentDepartment = { key: { id: '' } },
      currentUser
    } = this.props;
    if (this.isSaveDepartmentButtonActive()) {
      updateDepartment(
        {
          ...department,
          name: departmentName.trim(),
          // $FlowFixMe no typing support for pathOr-function
          ownerDepartment: { id: R.pathOr('', ['key', 'id'], parentDepartment).trim() }
        },
        createCsrfHeader(currentUser)
      );
      this.setState({ viewMode: 'view' });
    }
  }

  handleOnDelete: *;

  handleOnDelete(movePeopleBeforeDelete: boolean) {
    const { onDeleteDepartment } = this.props;

    this.setState({
      isDeleting: true
    });

    onDeleteDepartment(movePeopleBeforeDelete);
  }

  showRemoveContent: boolean => void;

  showRemoveContent(value: boolean) {
    this.setState({ cannotRemoveError: false, removeActive: value });
  }

  showCannotRemoveError: boolean => void;

  showCannotRemoveError(value: boolean) {
    this.setState({ cannotRemoveError: value });
  }

  render(): Element<'div'> {
    const { department, departmentUsers, hasSubDepartments, t } = this.props;
    const viewModeButtons = (
      <div className={styles['button-container']}>
        <ConfirmButton
          id="department-details-modify-button"
          className={styles['modify-button']}
          label={t('enterprise.departmentDetails.modifyDepartment')}
          onClickAction={() => this.setState({ viewMode: 'edit' })}
        />

        <ConfirmButton
          id="department-details-remove-button"
          className={styles['delete-button']}
          label={t('enterprise.departmentDetails.removeDepartment')}
          onClickAction={() =>
            hasSubDepartments ? this.showCannotRemoveError(true) : this.showRemoveContent(true)
          }
          disabled={hasSubDepartments === true}
        />
        {hasSubDepartments && (
          <Tooltip containerStyle={styles.tooltip}>
            {t('enterprise.departmentDetails.removeDepartmentTooltip')}
          </Tooltip>
        )}
      </div>
    );

    const editModeButtons = (
      <div className={styles['button-container']}>
        <ActionButton
          id="save-department-name-button"
          label={t('enterprise.departmentDetails.departmentNameSaveButton')}
          onClickAction={this.handleUpdateDepartment}
          disabled={!this.isSaveDepartmentButtonActive()}
        />
        <CancelButton
          id="department-details-cancel-button"
          className={styles['cancel-button']}
          label={t('enterprise.departmentDetails.cancelButton')}
          onClickAction={() => this.setState({ viewMode: 'view' })}
        />
      </div>
    );
    const modifyDepartmentView = (
      <div>
        <div className={styles.input}>
          <FormSection id="modify-section" name="modify">
            <Field
              id="nameField"
              key="nameField"
              label={t('enterprise.departmentDetails.departmentNameLabel')}
              name="nameField"
              type="light"
              style={{ width: '300px', float: 'left' }}
              component={FormInput}
              validate={this.validateDepartmentName}
              isSectionInEditMode={this.state.viewMode === 'edit'}
              labelClassName={styles.label}
            />
            {SHOW_DEPARTMENT_PARENT_SELECTION && (
              <Field
                id="parentDepartment"
                key="parentDepartment"
                label={`${t('enterprise.departmentDetails.parentDepartmentLabel')}`}
                name="parentDepartment"
                options={this.props.departmentIdTree}
                component={FormIdTreeCombobox}
                wrapperStyle={styles['static-dropdown']}
                viewModeOnClick={() => {}}
                hideElementWithId={this.props.department.id}
                hasEmptyOption
                emptySelection={{
                  key: NO_VALUE_FIELD_ID,
                  value: t('enterprise.departmentDetails.noParentDepartment')
                }}
                isSectionInEditMode={this.state.viewMode === 'edit'}
              />
            )}
          </FormSection>
        </div>
        {this.state.viewMode === 'view' && viewModeButtons}
        {this.state.viewMode === 'edit' && editModeButtons}
      </div>
    );

    return (
      <div className={styles['modify-container']}>
        {this.state.removeActive ? (
          <DepartmentDelete
            department={department}
            departmentIdTree={this.props.departmentIdTree}
            numberOfDepartmentUsers={departmentUsers.length}
            isConfirmDisabled={false}
            onClickDelete={this.handleOnDelete}
            onClickPeopleLink={() => this.props.setActiveTab('people')}
            showRemoveContent={this.showRemoveContent}
            isDeleting={this.state.isDeleting}
            targetDepartmentSelected={this.props.destinationDepartment != null}
          />
        ) : (
          modifyDepartmentView
        )}
        {this.state.cannotRemoveError && (
          <div role="status">
            {parse(
              `${t('enterprise.departmentDetails.cannotRemove1')}<br />${t(
                'enterprise.departmentDetails.cannotRemove2'
              )}`
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: PropsT) => {
  const { department, t } = ownProps;
  const selectFromForm = formValueSelector('departmentDetails');
  const departments = selectDepartments.departmentsByEnterprise(state, ownProps.enterpriseId);
  const destDepartment =
    departments.length > 0 ? departments.find(d => d.id !== department.id) : null;
  return {
    initialValues: {
      destinationDepartment: {
        value: destDepartment ? destDepartment.id : ''
      },
      modify: {
        nameField: department.name,
        parentDepartment: department.ownerDepartment
          ? {
              key: department.ownerDepartment,
              value: state.entities.department.byId[department.ownerDepartment.id].name
            }
          : {
              key: NO_VALUE_FIELD_ID,
              value: t('enterprise.departmentDetails.noParentDepartment')
            }
      }
    },
    departmentUsers: userSelect.byDepartmentId(state, ownProps.enterpriseId, department.id),
    departmentName: selectFromForm(state, 'modify.nameField'),
    parentDepartment: selectFromForm(state, 'modify.parentDepartment'),
    destinationDepartment: selectFromForm(state, 'destinationDepartment'),
    departmentIdTree: state.entities.department.idTrees[ownProps.enterpriseId],
    hasSubDepartments: selectDepartments.departmentHasSubDepartments(
      state,
      ownProps.enterpriseId,
      department.id
    ),
    currentUser: state.currentUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateDepartment: departmentOps.updateDepartment }, dispatch);

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(
    // $FlowFixMe
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'departmentDetails',
    enableReinitialize: true,
    destroyOnUnmount: false
  })
)(DepartmentModifyView);
