// @flow

/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, type Element } from 'react';
import { CancelToken, CancelTokenSource } from 'axios';
import queryString from 'querystring';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, type FormProps, reduxForm, formValueSelector, change } from 'redux-form';
import type { ContextRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../commonTypes';
import { InputField } from '../../components/InputComponent/ReduxFormField';
import {
  type CurrentUserStateT,
  type LoginStatusT,
  operations as currentUserOps,
  selectors as currentUserSelectors
} from '../../ducks/currentUser';
import { createSelectEnterpriseAction } from '../../ducks/currentUser/currentUserActions';
import {
  goTo,
  goToCallFlowServices,
  goToEnterpriseUsers,
  goToLandingPage
} from '../../navigationOperations';
import ActionButton from '../../components/Button/ActionButton';
import { operations } from '../../ducks/config';
import {
  createOpenChangelogAction,
  createOpenOnboardingAction
} from '../../ducks/ui/header/headerUiActions';
import LoginBase from './LoginBase';
import { getEnterprise } from '../../ducks/entities/enterprise/enterpriseOperations';
import { isBrowserRunningE2eTests, isIntProd, isProd } from '../../helpers';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import LoadingView from '../callFlows/callFlowGrid/gridElement/content/LoadingView';
import {
  isAcdManager,
  isMultiEnterpriseAdmin,
  isSingleEnterpriseAdmin,
  isSwitchboardUser
} from '../../utils/accessRightUtils';
import { getGotoDestination, getLoginConfigs, isUserLanguageValid } from './LoginUtil';
import type { UserConfigT } from '../../ducks/config';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import { USER_CLICKED_SSO_PASSWORD_RESET_LINK } from '../../matomo_constants';

import styles from './LoginBase.module.scss';

type OwnPropsT = {||};

type StatePropsT = {
  currentUser: CurrentUserStateT,
  loginStatus: LoginStatusT,
  hasSession: boolean,
  redirect: string,
  username: string,
  password: string,
  languages: *
};

type DispatchPropsT = {
  login: typeof currentUserOps.login,
  fetchCsrf: typeof currentUserOps.fetchCsrf,
  goTo: typeof goTo,
  goToEnterpriseUsers: typeof goToEnterpriseUsers,
  goToLandingPage: typeof goToLandingPage,
  goToCallFlowServices: typeof goToCallFlowServices,
  selectEnterprise: typeof createSelectEnterpriseAction,
  fetchUserConfig: typeof operations.getUserConfig,
  updateUserConfig: typeof operations.updateUserConfig,
  getEnterprise: typeof getEnterprise,
  openChangelog: typeof createOpenChangelogAction,
  openOnboarding: typeof createOpenOnboardingAction,
  getBulletins: typeof operations.getBulletins
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  // $FlowFixMe
  ...FormProps,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {|
  loadingEnterprise: 'initial' | 'loading' | 'done',
  error: boolean,
  elisaSSOLoginActive: boolean
|};

export class Login extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.requestCancelTokenSource = CancelToken.source();
    this.state = {
      loadingEnterprise: 'initial',
      error: false,
      elisaSSOLoginActive: false
    };
    this.forwardUserIfLoggedIn = this.forwardUserIfLoggedIn.bind(this);
    this.forwardUserIfLoggedIn();
  }

  async componentDidMount() {
    const { username } = qs.parse(this.props.location.search);
    if (username) {
      this.props.initialize({ username, password: '' });
    }
  }

  componentDidUpdate() {
    this.forwardUserIfLoggedIn();
  }

  componentWillUnmount() {
    this.requestCancelTokenSource.cancel();
  }

  forwardUserIfLoggedIn: () => void;

  forwardUserIfLoggedIn() {
    const {
      currentUser,
      goToLandingPage, // eslint-disable-line no-shadow
      goToEnterpriseUsers, // eslint-disable-line no-shadow
      goTo, // eslint-disable-line no-shadow
      goToCallFlowServices, // eslint-disable-line no-shadow
      redirect,
      hasSession
    } = this.props;
    const { loadingEnterprise } = this.state;

    const getEnterpriseFromCurrentUser = () => currentUser.enterprises?.[0] || null;

    if (hasSession) {
      let enterpriseFound = null;
      if (redirect) {
        const enterpriseIdToFind = redirect.match(/\/enterprises\/(\d+)/)?.[1];
        enterpriseFound = currentUser.enterprises?.find(
          enterprise => enterprise.id === enterpriseIdToFind
        );
      }
      if (redirect && enterpriseFound) {
        goTo(redirect);
      } else if (isSingleEnterpriseAdmin(currentUser) || isSwitchboardUser(currentUser)) {
        const enterprise = getEnterpriseFromCurrentUser();
        if (enterprise && (loadingEnterprise === 'done' || isBrowserRunningE2eTests())) {
          goToEnterpriseUsers(enterprise.id);
        }
      } else if (isMultiEnterpriseAdmin(currentUser)) {
        goToLandingPage();
      } else if (isAcdManager(currentUser)) {
        const enterprise = getEnterpriseFromCurrentUser();
        if (enterprise && (loadingEnterprise === 'done' || isBrowserRunningE2eTests())) {
          goToCallFlowServices(enterprise.id);
        }
      }
    }
  }

  requestCancelTokenSource: CancelTokenSource;

  async handleLoginSubmit(values: { username: string, password: string }): Promise<void> {
    const {
      login,
      fetchUserConfig,
      openOnboarding,
      openChangelog,
      selectEnterprise,
      updateUserConfig,
      goTo, // eslint-disable-line no-shadow
      getEnterprise, // eslint-disable-line no-shadow
      getBulletins,
      fetchCsrf,
      i18n
    } = this.props; // eslint-disable-line no-shadow
    const { username, password } = values;
    const currentUser: CurrentUserT = await login(username, password);
    if (currentUser.status === 'generic_login_error') {
      if (password) {
        this.setState({ error: true });
      }
      return;
    }
    const destination = getGotoDestination(currentUser, username, password);
    if (destination) {
      goTo(destination);
      return;
    }
    const csrfToken = await fetchCsrf();
    const userConfig: UserConfigT = await fetchUserConfig();
    const bulletins = await getBulletins();
    const { modifiedUserConfig, shouldOpenOnboarding, shouldOpenChangelog } = getLoginConfigs(
      `${currentUser.environment}${currentUser.country || ''}`,
      currentUser.enterprises?.map(enterprise => enterprise.ownerAdmtiveDomainId || '') || [],
      userConfig,
      bulletins
    );
    if (modifiedUserConfig) {
      await updateUserConfig(modifiedUserConfig, {
        'X-CSRF-TOKEN': csrfToken
      });
    }
    if (shouldOpenOnboarding) {
      openOnboarding();
    } else if (shouldOpenChangelog) {
      openChangelog();
    }
    if (!currentUser.multiEnterpriseAdmin) {
      this.setState({
        loadingEnterprise: 'loading'
      });
      const enterprise =
        currentUser.enterprises && currentUser.enterprises[0]
          ? await getEnterprise(currentUser.enterprises[0].id, CancelToken.source().token)
          : null;
      if (enterprise) {
        selectEnterprise(enterprise.entID, enterprise.fullName);
      }
      this.setState({
        loadingEnterprise: 'done'
      });
    }
    if (currentUser.appLanguage && isUserLanguageValid(currentUser.appLanguage)) {
      i18n.changeLanguage(currentUser.appLanguage);
    }
  }

  render(): Element<typeof LoginBase | 'div'> {
    // eslint-disable-next-line no-shadow
    const { t, handleSubmit, loginStatus, username, password } = this.props;
    const { loadingEnterprise, error, elisaSSOLoginActive } = this.state;
    const usernameInput = (
      <Field
        component={InputField}
        name="username"
        className={styles['login-input']}
        id="login-username"
        type="text"
        onValueChange={e => this.props.dispatch(change('login', 'username', e.currentTarget.value))}
        placeholder={t('login.usernamePlaceholder')}
        autofocus
        maxlength={85}
      />
    );

    const passwordInput = (
      <Field
        component={InputField}
        type="password"
        id="login-password"
        onValueChange={e => this.props.dispatch(change('login', 'password', e.currentTarget.value))}
        name="password"
        className={styles['login-input']}
        placeholder={t('login.passwordPlaceholder')}
        maxlength={50}
      />
    );

    const loginButton = (
      <ActionButton
        id="login-button"
        type="submit"
        className={styles['login-button']}
        label={t('login.loginButtonLabel')}
        loading={
          loginStatus === 'logging in' ||
          loginStatus === 'login done' ||
          loginStatus === 'fetching admin level' ||
          loadingEnterprise !== 'initial'
        }
        disabled={!username || !password}
      />
    );

    const environment = isProd() || isIntProd() ? 'prod' : 'test';

    return elisaSSOLoginActive ? (
      <div className={styles['loading-view']}>
        <LoadingView />
      </div>
    ) : (
      <LoginBase>
        <form onSubmit={handleSubmit(values => this.handleLoginSubmit(values))}>
          <h3 className="ea-h3 ea-pad ea-pad--pad-bottom-1" id="login-page-title">
            {t('login.pageTopic')}
          </h3>
          <div className={styles['login-description']}>{t('login.pageDescription')}</div>
          {usernameInput}
          {passwordInput}
          <div className={styles['button-area']}>
            {loginButton}
            {error && (
              <p id="login-error" role="status">
                {t('login.loginFailedMsg')}
              </p>
            )}
            <div>
              <a
                id="admin-password-missing-button"
                href={t(`login.passwordMissingLinks.${environment}`)}
                onClick={() => pushAnalyticsEvent(USER_CLICKED_SSO_PASSWORD_RESET_LINK)}
              >
                {t('login.passwordMissingLink')}
              </a>
            </div>
          </div>
        </form>
      </LoginBase>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: PropsT) => ({
  username: formValueSelector('login')(state, 'username'),
  password: formValueSelector('login')(state, 'password'),
  currentUser: state.currentUser,
  loginStatus: currentUserSelectors.getLoginStatus(state),
  hasSession: currentUserSelectors.hasSession(state),
  redirect: queryString.parse(ownProps.location.search.slice(1)).redirect
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: currentUserOps.login,
      fetchCsrf: currentUserOps.fetchCsrf,
      goTo,
      goToEnterpriseUsers,
      goToCallFlowServices,
      goToLandingPage,
      selectEnterprise: createSelectEnterpriseAction,
      fetchUserConfig: operations.getUserConfig,
      updateUserConfig: operations.updateUserConfig,
      getBulletins: operations.getBulletins,
      openOnboarding: createOpenOnboardingAction,
      openChangelog: createOpenChangelogAction,
      getEnterprise
    },
    dispatch
  );
export default compose(
  withTranslation(),
  reduxForm({
    form: 'login',
    enableReinitialize: false
  }),
  // $FlowFixMe
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(Login);
