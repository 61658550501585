// @flow
import React, { type Element, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CancelToken, CancelTokenSource } from 'axios';
import { useSelector } from 'react-redux';
import type { AudioFieldT, PropertyAudioT } from './CallflowAudioUtils';
import AudioFileDropzone from '../../../../../../components/AudioFileDropzone/AudioFileDropzone';
import AcdAudioListItem from './AcdAudioListItem';
import { uploadAudioProperty } from './CallflowAudioUtils';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';
import { stripExtension } from '../../../../../../helpers';
import TrashBinButton from '../../../../../../components/Button/TrashBinButton';
import styles from './AcdAudioFieldList.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string
|};

let audioRequestCancelTokenSource: CancelTokenSource;
export const AcdAudioFieldList = (props: PropsT): Element<'div'> => {
  const { field, title, description } = props;
  const { t } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const audioField: AudioFieldT<PropertyAudioT> = values[field];
  const audioPlaylist = audioField?.audioPlayData?.filename
    ? audioField.audioPlayData.filename.split(',').filter(entry => entry.length > 0)
    : [];
  const [uploading, setUploading] = useState();

  useEffect(() => {
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      audioRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={title && styles['title-padding']}>
      {title && <div className={styles['small-title']}>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div>
        <AudioFileDropzone
          // $FlowFixMe
          onChange={async file => {
            if (audioPlaylist.includes(stripExtension(file.name))) {
              // $FlowFixMe
              return;
            }
            setUploading(true);
            await uploadAudioProperty(
              { ...audioField, fileToImport: file },
              file,
              audioRequestCancelTokenSource,
              createCsrfHeader(currentUser)
            );
            setUploading(false);
            setValue(
              field,
              {
                ...audioField,
                level: 'Extension',
                audioPlayData: {
                  ...audioField.audioPlayData,
                  filename:
                    audioField.level === 'Extension'
                      ? `${audioField.audioPlayData.filename}${
                          audioField.audioPlayData.filename ? ',' : ''
                        }${stripExtension(file.name)}`
                      : stripExtension(file.name)
                }
              },
              {
                shouldValidate: false,
                shouldDirty: true
              }
            );
          }}
          forceSelectFile={!uploading}
        />
      </div>
      {audioPlaylist.map((audioFilename, index) => (
        <div className={styles['audio-line']}>
          <AcdAudioListItem field={field} audioFilename={audioFilename} />
          {audioField.level === 'Extension' && (
            <TrashBinButton
              id={`remove-audio-${index}`}
              style={styles.trashbin}
              onClose={() => {
                const audioList = audioPlaylist
                  .filter(filename => filename !== audioFilename)
                  .join(',');

                setValue(
                  field,
                  {
                    ...audioField,
                    level: audioList ? 'Extension' : 'Default',
                    audioPlayData: {
                      ...audioField.audioPlayData,
                      filename: audioList
                    }
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                );
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default AcdAudioFieldList;
