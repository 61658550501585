// @flow

import classNames from 'classnames';
import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, reset } from 'redux-form';
import { DragDropContext, Droppable, Draggable, type DropResult } from 'react-beautiful-dnd';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import { LaptopAndUp } from '../../Responsive';
import ActionButton from '../Button/ActionButton';
import Dismiss from '../Button/Dismiss';
import Search from '../Search/Search';
import UserItem from '../UserItem/UserItem';
import OrderableItem from '../OrderableItem/OrderableItem';
import styles from './UserSelector.module.scss';

type OwnPropsT = {
  searchResults: Array<InternalUserStateEntityT>,
  preselection?: Array<InternalUserStateEntityT>,
  onSearchTermChange: (searchTerm: string) => void,
  onSearchTermClear: () => void,
  onSelectionChange: (Array<InternalUserStateEntityT>) => void,
  errorMessage: string,
  isLoadingSearchResults?: boolean,
  hideButtons: boolean,
  maxSelected: number,
  selectedOrderable?: boolean,
  onReOrderUsers?: (Array<InternalUserStateEntityT>) => void,
  isAcd?: boolean
};

type StatePropsT = {};

type DispatchPropsT = {
  reset: () => void
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  selectedItems: Array<InternalUserStateEntityT>,
  showMoreOpen: boolean
};

export const MAX_SMALL_SCREEN_SELECTED_USERS_COUNT = 5;

export class UserSelector extends Component<PropsT, StateT> {
  static defaultProps = {
    isLoadingSearchResults: false,
    maxSelected: Infinity
  };

  constructor(props: PropsT) {
    super(props);
    this.handleSearchInputChanged = this.handleSearchInputChanged.bind(this);
    this.handleSearchInputCleared = this.handleSearchInputCleared.bind(this);
    this.renderResultItem = this.renderResultItem.bind(this);
    this.renderSelectedList = this.renderSelectedList.bind(this);
    this.handleResultItemClicked = this.handleResultItemClicked.bind(this);
    this.handleSelectedItemDismissed = this.handleSelectedItemDismissed.bind(this);
    this.handleClickDeselectAll = this.handleClickDeselectAll.bind(this);
    this.handleClickSelectAll = this.handleClickSelectAll.bind(this);
    this.handleSelectedUsersOnDragEnd = this.handleSelectedUsersOnDragEnd.bind(this);
  }

  state = {
    selectedItems: this.props.preselection ? this.props.preselection : [],
    showMoreOpen: false
  };

  handleSearchInputChanged: () => void;

  handleSearchInputChanged(searchTerm: string) {
    // eslint-disable-next-line no-shadow
    const { onSearchTermChange, onSearchTermClear, reset } = this.props;
    if (searchTerm === '') {
      reset();
      onSearchTermClear();
    }
    onSearchTermChange(searchTerm);
  }

  handleSearchInputCleared: () => void;

  handleSearchInputCleared() {
    const { onSearchTermClear, reset } = this.props; // eslint-disable-line no-shadow
    reset();
    onSearchTermClear();
  }

  handleResultItemClicked: (item: InternalUserStateEntityT) => void;

  handleResultItemClicked(item: InternalUserStateEntityT) {
    this.setState(({ selectedItems }) => {
      const { onSelectionChange, maxSelected } = this.props;
      const newSelectedItems = R.uniq([...selectedItems, item]);

      if (newSelectedItems.length > maxSelected && maxSelected !== -1) {
        return { selectedItems };
      }

      if (!R.equals(newSelectedItems, selectedItems)) {
        onSelectionChange(newSelectedItems);
      }
      return {
        selectedItems: newSelectedItems
      };
    });
  }

  handleSelectedItemDismissed: (item: InternalUserStateEntityT) => void;

  handleSelectedItemDismissed(item: InternalUserStateEntityT) {
    const { onSelectionChange } = this.props;
    this.setState(({ selectedItems }) => {
      const newSelectedItems = R.reject(({ id }) => id === item.id, selectedItems);
      onSelectionChange(newSelectedItems);
      return {
        selectedItems: newSelectedItems
      };
    });
  }

  handleClickDeselectAll: () => void;

  handleClickDeselectAll() {
    const { onSelectionChange } = this.props;
    this.setState({ selectedItems: [] });
    onSelectionChange([]);
  }

  handleClickSelectAll: () => void;

  handleClickSelectAll() {
    const { searchResults, onSelectionChange } = this.props;

    this.setState(({ selectedItems }) => {
      // merge with existing selections by keeping their order
      const newSelected = R.unionWith(R.propEq('id'), selectedItems, searchResults);
      onSelectionChange(newSelected);
      return { selectedItems: newSelected };
    });
  }

  handleSelectedUsersOnDragEnd: DropResult => void;

  handleSelectedUsersOnDragEnd(dragResult: DropResult) {
    const { source, destination } = dragResult;
    const { onReOrderUsers } = this.props;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    this.setState(({ selectedItems }) => {
      // $FlowFixMe move missing in typedefs
      const reorderedItems = R.move(source.index, destination.index, selectedItems);
      if (onReOrderUsers) {
        onReOrderUsers(reorderedItems);
      }
      return {
        selectedItems: reorderedItems
      };
    });
  }

  renderResultItem: (*) => Element<'li'> | null;

  renderResultItem(item: InternalUserStateEntityT) {
    return this.state.selectedItems.some(({ id }) => id === item.id) ? null : (
      // eslint-disable-next-line
      <li key={item.id} onClick={() => this.handleResultItemClicked(item)}>
        <UserItem className={styles['result-user-item']} user={item} />
      </li>
    );
  }

  renderFirstResultItem: () => ?Element<'li'>;

  renderFirstResultItem(): ?Element<'li'> {
    const { searchResults, t, hideButtons } = this.props;
    const { selectedItems } = this.state;

    const count = R.filter(user => !selectedItems.includes(user), searchResults).length;

    return !hideButtons && count > 0 ? (
      <li className={styles['first-result-item']} key="_firstResultItem">
        <button onClick={this.handleClickSelectAll} className={styles['link-button']}>
          {t('forwardingDetails.userSelector.selectAllSmall', {
            count
          })}
        </button>
      </li>
    ) : null;
  }

  renderSelectedItem: (InternalUserStateEntityT, number) => Element<typeof Draggable>;

  renderSelectedItem(item: InternalUserStateEntityT, index: number): Element<typeof Draggable> {
    const { selectedOrderable } = this.props;

    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={!selectedOrderable}
      >
        {provided => (
          <div
            className={styles['selected-item']}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            {selectedOrderable ? (
              <OrderableItem
                dragHandleProps={provided.dragHandleProps}
                ordinal={index + 1}
                className={styles['selected-user-item']}
              >
                <UserItem user={item} />
              </OrderableItem>
            ) : (
              <UserItem className={styles['selected-user-item']} user={item} />
            )}
            <Dismiss
              id={`dismiss-selected-user-${item.id}`}
              onClose={() => this.handleSelectedItemDismissed(item)}
            />
          </div>
        )}
      </Draggable>
    );
  }

  renderSelectedList: () => Element<'div'>;

  renderSelectedList(): Element<'div'> {
    const { t, hideButtons, maxSelected, selectedOrderable, isAcd } = this.props;
    const { selectedItems, showMoreOpen } = this.state;

    return (
      <div className={styles['selected-container']}>
        <div className={styles['selected-description']}>
          {selectedItems.length > 0 ? (
            <span>
              {maxSelected === -1 || maxSelected === Infinity
                ? isAcd
                  ? t('forwardingDetails.userSelector.selectedAcdsDescriptionWithCount', {
                      count: selectedItems.length
                    })
                  : t('forwardingDetails.userSelector.selectedUsersDescriptionWithCount', {
                      count: selectedItems.length
                    })
                : t('forwardingDetails.userSelector.selectedUsersDescriptionWithCountAndMax', {
                    count: selectedItems.length,
                    max: maxSelected
                  })}
            </span>
          ) : (
            <div>
              <div>
                {isAcd
                  ? t('forwardingDetails.userSelector.selectedAcds')
                  : t('forwardingDetails.userSelector.selectedUsers')}
              </div>
              <div className={styles['select-users']}>
                {t('forwardingDetails.userSelector.selectedUsersDescription')}
              </div>
            </div>
          )}
        </div>
        <div className={styles['selected-description-small']}>
          {!hideButtons && selectedItems.length > 0 && (
            <button onClick={this.handleClickDeselectAll} className={styles['link-button']}>
              {t('forwardingDetails.userSelector.deselectAllSmall', {
                count: selectedItems.length
              })}
            </button>
          )}
        </div>
        <Droppable droppableId="selected-users-droppable" isDropDisabled={!selectedOrderable}>
          {provided => (
            <div
              className={styles['selected-items']}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <LaptopAndUp>
                {laptopAndUp => {
                  if (laptopAndUp) {
                    return (
                      <>
                        {selectedItems.map((item, index) => this.renderSelectedItem(item, index))}
                        {provided.placeholder}
                      </>
                    );
                  }
                  const showMore =
                    selectedItems.length > MAX_SMALL_SCREEN_SELECTED_USERS_COUNT && !showMoreOpen;
                  const showLess =
                    selectedItems.length > MAX_SMALL_SCREEN_SELECTED_USERS_COUNT && showMoreOpen;
                  return (
                    <>
                      {selectedItems
                        .filter(
                          (_, index) =>
                            index < MAX_SMALL_SCREEN_SELECTED_USERS_COUNT || showMoreOpen
                        )
                        .map((item, index) => this.renderSelectedItem(item, index))}
                      {provided.placeholder}
                      {showMore && (
                        <button
                          className={styles['show-more-less-link-button']}
                          onClick={() => this.setState({ showMoreOpen: true })}
                        >
                          {t('forwardingDetails.userSelector.showAll')}
                        </button>
                      )}
                      {showLess && (
                        <button
                          className={styles['show-more-less-link-button']}
                          onClick={() => this.setState({ showMoreOpen: false })}
                        >
                          {t('forwardingDetails.userSelector.showLess')}
                        </button>
                      )}
                    </>
                  );
                }}
              </LaptopAndUp>
            </div>
          )}
        </Droppable>
      </div>
    );
  }

  render() {
    const {
      hideButtons,
      searchResults,
      t,
      errorMessage,
      isLoadingSearchResults,
      maxSelected,
      isAcd
    } = this.props;
    const { selectedItems } = this.state;
    return (
      <DragDropContext onDragEnd={this.handleSelectedUsersOnDragEnd}>
        <div className={styles.container}>
          {errorMessage && (
            <div data-cy="user-selector-error" className={styles['specific-validation-error']}>
              {errorMessage}
            </div>
          )}
          {/* $FlowFixMe: Search component's types should be completely rewritten again */}
          <Search
            id="UserSelectorInput"
            icon="search"
            inputLabel={
              isAcd
                ? t('forwardingDetails.userSelector.searchInputPlaceholderAcds')
                : t('forwardingDetails.userSelector.searchInputPlaceholderUsers')
            }
            searchClassName={styles['search-content']}
            searchInputContainerClassName={styles['search-input-container']}
            resultsClassName={styles['search-results']}
            searchResults={searchResults}
            renderResultItem={this.renderResultItem}
            renderSelectedList={this.renderSelectedList}
            isSectionInEditMode
            selectedItems={selectedItems}
            searchInputChanged={this.handleSearchInputChanged}
            searchInputCleared={this.handleSearchInputCleared}
            maxSearchResults={Infinity}
            isLoadingSearchResults={isLoadingSearchResults}
            firstResultElement={this.renderFirstResultItem()}
          />
          {!hideButtons && (
            <div className={styles['buttons-container']}>
              <ActionButton
                id="deselect-all-users"
                onClickAction={this.handleClickDeselectAll}
                className={styles['deselect-button']}
                label={t('forwardingDetails.userSelector.deselectAll')}
                disabled={selectedItems.length < 1}
              />
              <ActionButton
                id="select-all-users"
                onClickAction={this.handleClickSelectAll}
                className={classNames({
                  [styles['select-button']]: true,
                  [styles.hide]: maxSelected !== Infinity && searchResults.length > maxSelected
                })}
                label={t('forwardingDetails.userSelector.selectAll')}
              />
            </div>
          )}
        </div>
      </DragDropContext>
    );
  }
}

// $FlowFixMe
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset('userSelector'))
});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'userSelector' })
)(UserSelector);
