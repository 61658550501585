// @flow strict-local

import React, { type Element } from 'react';
import { connect, useSelector } from 'react-redux';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { RingPatternT } from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import type { ExactPropsT } from '../../../../../commonTypes';

import styles from './CallDistributionSelection.module.scss';

type OwnPropsT = {|
  ringPattern: RingPatternT,
  updateCallDistributionTarget: RingPatternT => void,
  errorMessage: string,
  numberOfMembers: number,
  maxMembers: number,
  unisonMaxMembers: number,
  overflowGroupEnabled?: boolean,
  isACD?: boolean,
  periodicStatsResetMoments?: string
|};

type StatePropsT = {||};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const CallDistributionSelection = ({
  ringPattern,
  updateCallDistributionTarget,
  errorMessage,
  numberOfMembers,
  maxMembers,
  unisonMaxMembers,
  overflowGroupEnabled,
  isACD,
  periodicStatsResetMoments
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);

  const getHelpText = () => {
    if (ringPattern === 'oldestIdle') {
      return t('callflows.callDistributionSelection.tooltip.oldestIdle');
    }
    if (ringPattern === 'sequential') {
      return t('callflows.callDistributionSelection.tooltip.sequential');
    }
    if (ringPattern === 'cyclic') {
      return t('callflows.callDistributionSelection.tooltip.cyclic');
    }
    if (ringPattern === 'leastTalking') {
      return t('callflows.callDistributionSelection.tooltip.leastTalking', {
        periodicStatsResetMoments
      });
    }
    if (ringPattern === 'leastCalls') {
      return t('callflows.callDistributionSelection.tooltip.leastCalls', {
        periodicStatsResetMoments
      });
    }
    if (ringPattern === 'unisson') {
      return t('callflows.callDistributionSelection.tooltip.unisson');
    }
    return '';
  };

  return (
    <div className={styles.container} id="call-distribution-selection">
      {errorMessage && <div className={styles['specific-validation-error']}>{errorMessage}</div>}
      <RadioGroup value={ringPattern} i18n_radiogroup_helpText={getHelpText()}>
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-1"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('oldestIdle')}
          label={t('callflows.callDistributionSelection.oldestIdle') || ''}
          value="oldestIdle"
          disabled={numberOfMembers > maxMembers}
        />
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-2"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('sequential')}
          label={t('callflows.callDistributionSelection.sequential') || ''}
          value="sequential"
          disabled={numberOfMembers > maxMembers}
        />
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-3"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('cyclic')}
          label={t('callflows.callDistributionSelection.cyclic') || ''}
          value="cyclic"
          disabled={numberOfMembers > maxMembers}
        />
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-4"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('leastTalking')}
          label={t('callflows.callDistributionSelection.leastTalking') || ''}
          value="leastTalking"
          className={classnames({
            [styles.hidden]:
              !((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ||
              !isACD
          })}
          disabled={numberOfMembers > maxMembers}
        />
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-5"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('leastCalls')}
          label={t('callflows.callDistributionSelection.leastCalls') || ''}
          value="leastCalls"
          className={classnames({
            [styles.hidden]:
              !((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ||
              !isACD
          })}
          disabled={numberOfMembers > maxMembers}
        />
        <Radio
          id="callflow-ext-group-details-ring-pattern-option-6"
          name="ring-pattern-option"
          onChange={() => updateCallDistributionTarget('unisson')}
          label={t('callflows.callDistributionSelection.unisson') || ''}
          value="unisson"
          disabled={numberOfMembers > unisonMaxMembers || overflowGroupEnabled}
        />
      </RadioGroup>
      {overflowGroupEnabled && <div>{t('callflows.callDistributionSelection.overflowInUse')}</div>}
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(CallDistributionSelection);
