/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios, { AxiosPromise } from 'axios';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import IconCheckRegular from '@design-system/component-library/src/components/Icon/lib/IconCheckRegular';
import { Button } from '@design-system/component-library';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import type { BaseCalendarEntityT } from '../../../../../../../ducks/entities/calendar/calendarTypes';
import Dialog from '../../../../../../../components/Dialog';
import DeleteCalendarModal from '../../../../../calendar/delete/DeleteCalendarModal';
import { sortAndRenderDaySchedule } from '../../../../../callFlowGrid/details/CalendarUtils';
import {
  findTitleForDate,
  filterVisibleDates
} from '../../../../../calendarTemplates/calendarTemplateUtil';
import { goToCreateAndAttachCalendarTemplate } from '../../../../../../../navigationOperations';
import DateTable from '../../../../../calendarTemplates/DateTable';
import OpeningHoursDialog from '../../../../OpeningHoursDialog/OpeningHoursDialog';
import styles from './CalendarSection.module.scss';

export type PropsT = {
  calendar: BaseCalendarEntityT,
  enterpriseId: string,
  bankHolidays?: any,
  availableTemplates?: [],
  saveDateFn?: (calendarConfigId: string, calendarId: string, date: any) => Promise<void>,
  deleteDateFn?: (calendarConfigId: string, calendarId: string, date: any) => Promise<void>,
  setTemplateFn?: (calendarConfigId: string, calendarId: string, date: any) => Promise<void>,
  editWeekScheduleFn?: () => void,
  calendarServices?: [],
  isLoadingServicesData?: boolean,
  refreshToken?: string,
  editable: boolean
};

export function CalendarSection(props: PropsT): Element<'div'> {
  const {
    calendar,
    calendarServices,
    isLoadingServicesData,
    enterpriseId,
    bankHolidays,
    availableTemplates,
    saveDateFn,
    deleteDateFn,
    setTemplateFn,
    editWeekScheduleFn,
    refreshToken,
    editable
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [showDeleteCalendarModal, setShowDeleteCalendarModal] = useState(false);
  const [showDeleteDateModal, setShowDeleteDateModal] = useState(null);
  const [showUseTemplateModal, setShowUseTemplateModal] = useState(false);
  const [calendarConfig, setCalendarConfig] = useState();
  const [isLoadingCalendarConfig, setIsLoadingCalendarConfig] = useState(false);
  const isDefaultCalendar = ownerAdmtiveDomainId => ownerAdmtiveDomainId === '0.';

  const editDate = dateEntry => {
    setDatePickerSelectedDate(dateEntry);
    setIsDatePickerVisible(true);
  };

  const isDefaultTemplate = (template): boolean => {
    return template.enterpriseId === '0';
  };

  const getTemplateName = (template): string => {
    return isDefaultTemplate(template)
      ? t(`calendars.templates.defaultNames.${template.name}`)
      : template.name;
  };

  const loadCalendarConfig = async (calendarId: string) => {
    if (!isDefaultCalendar(calendar.ownerAdmtiveDomainId)) {
      setIsLoadingCalendarConfig(true);
      try {
        const cResponse: AxiosPromise = await axios({
          method: 'GET',
          url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/byplatformid/${calendarId}`
        });
        if (!cResponse || !cResponse.data) {
          throw new Error('Error getting calendar data');
        }
        const cdata = cResponse.data;
        cdata.dates = cdata.effectiveDates.map(s => {
          return {
            ...s,
            title: findTitleForDate(s.date, bankHolidays || [])
          };
        });
        cdata.dates = filterVisibleDates(cdata.dates);

        setCalendarConfig(cdata);
      } catch (error) {
        console.error('Error', error);
      }
    }
    setIsLoadingCalendarConfig(false);
  };

  useEffect(() => {
    loadCalendarConfig(calendar.id);
  }, [calendar, refreshToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHours = day => (
    <div>
      {day && day.length > 0 ? (
        sortAndRenderDaySchedule(day)
      ) : (
        <div>{t('callflows.details.closed')}</div>
      )}
    </div>
  );

  const renderOpeningHours = () => {
    const weekSlots = R.path(['weekSchedule'], calendar);
    return (
      weekSlots && (
        <div className={styles.section}>
          <div>
            <div className={styles['section-title']}>{t('callflows.details.openingHours')}</div>
            <table className={styles['date-table']}>
              <thead>
                <tr>
                  <th>{t('calendars.templates.date')}</th>
                  <th>{t('calendars.templates.slots')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(weekSlots).map(key => (
                  <tr key={key}>
                    <td>
                      <div>{t(`callflows.details.${key}`)}</div>
                    </td>
                    <td>{renderHours(weekSlots[key])}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    );
  };

  const renderServices = () => {
    return (
      calendarServices !== undefined && (
        <div className={styles['sub-section']}>
          <div className={styles['section-title']}>{t('callflows.details.services')}</div>
          {isLoadingServicesData ? (
            <LoadingSpinner />
          ) : calendarServices && calendarServices.length > 0 ? (
            calendarServices.map(cal => <div>{`${cal.label} (${cal.addressNumber})`}</div>)
          ) : (
            <div>{t('callflows.details.noServices')}</div>
          )}
        </div>
      )
    );
  };

  return (
    <div className={styles['calendar-section']}>
      {renderOpeningHours()}

      {editable && (
        <div>
          <Button
            className={styles.button}
            id="edit-week-schedule-button"
            size="m"
            color="light"
            onClick={() => {
              if (editWeekScheduleFn) editWeekScheduleFn();
            }}
          >
            {t('callflow.modifyLabel')}
          </Button>
        </div>
      )}

      <div>
        <div className={styles['section-title']}>
          {t('callflows.details.absoluteTimeSlotsTitle')}
        </div>
        {isLoadingCalendarConfig ? (
          <LoadingSpinner />
        ) : (
          calendarConfig && (
            <DateTable
              editable={editable}
              templateName={
                calendarConfig && calendarConfig.calendarTemplate
                  ? getTemplateName(calendarConfig.calendarTemplate)
                  : ''
              }
              dates={calendarConfig?.dates}
              onEdit={d => {
                editDate(d);
              }}
              onDelete={d => {
                setShowDeleteDateModal(d);
              }}
            />
          )
        )}
      </div>

      {editable && !isLoadingCalendarConfig && (
        <div>
          <div className={styles['flex-container']}>
            <div>
              <div className={styles['section-title']}>{t('calendars.useTemplateTitle')}</div>
              <div className={styles['info-text']}>{t('calendars.useTemplateHelp')}</div>
              <Button
                className={styles.button}
                size="m"
                color="light"
                onClick={() => {
                  setShowUseTemplateModal(true);
                }}
              >
                {calendarConfig && calendarConfig.calendarTemplate ? (
                  <>
                    <IconCheckRegular />
                    {getTemplateName(calendarConfig.calendarTemplate)}
                  </>
                ) : (
                  <>{t('calendars.useTemplateButton')}</>
                )}
              </Button>
            </div>
            <div>
              <div className={styles['section-title']}>
                {t('calendars.templates.singleDateTitle')}
              </div>
              <div className={styles['info-text']}>
                {t('calendars.templates.singleDateInfoText')}
              </div>
              <Button
                className={styles.button}
                size="m"
                id="add-exceptional-date-button"
                color="light"
                onClick={() => {
                  editDate(null);
                }}
              >
                {t('calendars.templates.singleDateButton')}
              </Button>
            </div>
          </div>

          <div className={styles['flex-container']}>
            <div>
              <Button
                className={styles.button}
                id="delete-calendar-button"
                size="m"
                color="light"
                onClick={() => {
                  setShowDeleteCalendarModal(true);
                }}
              >
                {t('calendars.editCalendarForm.calendarDeleteButton')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {renderServices()}

      {isDatePickerVisible && editable && (
        <OpeningHoursDialog
          title={t('calendars.templates.addDateDialogTitle')}
          holidays={bankHolidays}
          dates={calendarConfig?.dates}
          initialDate={datePickerSelectedDate}
          onCancel={() => {
            setIsDatePickerVisible(false);
            setDatePickerSelectedDate(null);
          }}
          onConfirm={val => {
            if (calendar && calendarConfig && saveDateFn) {
              setIsLoadingCalendarConfig(true);
              saveDateFn(calendarConfig.id, calendar.id, val);
            }
            setIsDatePickerVisible(false);
            setDatePickerSelectedDate(null);
          }}
          onDelete={val => {
            setShowDeleteDateModal(val);
          }}
        />
      )}

      {showUseTemplateModal && editable && (
        <div
          className="ea-modal ea-modal--open styleguide-dialog-position"
          role="dialog"
          aria-modal="true"
        >
          <div className="ea-modal__overlay" />
          <div className={`ea-modal__content  ${styles['modal-content']}`}>
            <div className={styles['section-title']}>{t('calendars.useTemplateButton')}</div>
            <div>{t('calendars.templates.helpText')}</div>
            <RadioGroup
              value={
                calendarConfig && calendarConfig.calendarTemplate
                  ? `${calendarConfig.calendarTemplate.id}`
                  : '0'
              }
              key="selectTemplate"
              className={styles['radio-group']}
            >
              {[
                {
                  id: '0',
                  name: t('callflows.valueNotSet'),
                  enterpriseId: '',
                  value: '0',
                  onChange: () => {
                    setSelectedTemplateId(null);
                  }
                },
                ...(availableTemplates || [])
              ].map(
                template =>
                  template && (
                    <Radio
                      id={`${template.id}`}
                      name="templateSelector"
                      label={getTemplateName(template)}
                      key={`${template.id}`}
                      value={`${template.id}`}
                      onChange={() => {
                        setSelectedTemplateId(template.id);
                      }}
                    />
                  )
              )}
            </RadioGroup>
            <Button
              className={styles['link-button']}
              color="link"
              onClick={() => {
                dispatch(goToCreateAndAttachCalendarTemplate(enterpriseId, calendar.id));
              }}
            >
              {t('calendars.createTemplateButton')}
            </Button>
            <div className={styles['button-section']}>
              <Button
                className={styles.button}
                size="l"
                color="link"
                onClick={() => {
                  setShowUseTemplateModal(false);
                }}
              >
                {t('calendars.templates.dialogActions.cancel')}
              </Button>
              <Button
                className={styles.button}
                size="l"
                color="primary"
                onClick={() => {
                  if (calendar && calendarConfig && setTemplateFn) {
                    setIsLoadingCalendarConfig(true);
                    setTemplateFn(
                      calendarConfig.id,
                      calendar.id,
                      selectedTemplateId && selectedTemplateId !== '0' ? selectedTemplateId : null
                    );
                  }
                  setShowUseTemplateModal(false);
                }}
              >
                {t('calendars.templates.dialogActions.confirm')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {showDeleteDateModal ? (
        <Dialog
          onCancel={() => setShowDeleteDateModal(null)}
          onConfirm={() => {
            if (calendar && calendarConfig && deleteDateFn) {
              setIsLoadingCalendarConfig(true);
              deleteDateFn(calendarConfig.id, calendar.id, showDeleteDateModal);
            }
            setIsDatePickerVisible(false);
            setDatePickerSelectedDate(null);
            setShowDeleteDateModal(null);
          }}
          onClose={() => {
            setShowDeleteDateModal(null);
          }}
          title={t('calendars.templates.deleteDateDialogTitle')}
          description={`${Moment(showDeleteDateModal.date).format('D.M.YYYY')} ${
            showDeleteDateModal.title
          }
                    ${
                      showDeleteDateModal.closedAllDay
                        ? t('callflows.details.closed')
                        : showDeleteDateModal.slots.map(s => {
                            return `${s.startTime.substring(0, 5)}-${s.endTime.substring(0, 5)}`;
                          })
                    }
                    ${
                      showDeleteDateModal.repeatsYearly ||
                      showDeleteDateModal.repeatsYearlyAtHoliday
                        ? `, ${t('calendars.templates.addHolidaysDialogRepeatLabel')}`
                        : ''
                    }`}
          confirmLabel={t('calendars.templates.dialogActions.confirm')}
          cancelLabel={t('calendars.templates.dialogActions.cancel')}
          loading={false}
          disabled={false}
        />
      ) : null}

      {showDeleteCalendarModal && (
        <DeleteCalendarModal
          calendarName={calendar ? calendar.name : ''}
          enterpriseId={enterpriseId}
          calendarId={calendar.id}
          onClose={() => setShowDeleteCalendarModal(false)}
        />
      )}
    </div>
  );
}

export default CalendarSection;
