// @flow

import classNames from 'classnames';
import * as R from 'ramda';
import Truncate from 'react-truncate-markup';
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Field, FormSection, reduxForm, type FormProps, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../commonTypes';
import Tooltip from '../../../components/Tooltip';
import { IdTreeCombobox, InputField } from '../../../components/InputComponent/ReduxFormField';
import fieldValidators from '../../../fieldValidators';
import DepartmentComboboxItem from '../../enterprises/DepartmentDetails/DepartmentComboboxItem';
import type { IdTreeT } from '../../../ducks/entities/department/departmentTypes';
import CancelButton from '../../../components/Button/CancelButton';
import ActionButton from '../../../components/Button/ActionButton';
import LinkButton from '../../../components/Button/LinkButton';
import {
  actionCreators as userimportUiActions,
  selectors as userImportUiSelectors
} from '../../../ducks/ui/userImport';
import type {
  ImportDepartmentItemT,
  ImportDepartmentTypeT
} from '../../../ducks/ui/userImport/userImportUiTypes';
import type {
  CreateSetDepartmentItemFnT,
  CreateUnsetDepartmentItemFnT
} from '../../../ducks/ui/userImport/userImportUiActions';
import {
  operations as departmentOps,
  selectors as selectDepartments
} from '../../../ducks/entities/department';
import { ROOT_DEPARTMENT_KEY } from '../../enterprises/DepartmentDetails/CreateDepartmentModal';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import * as departmentSelectors from '../../../ducks/entities/department/departmentSelectors';

import styles from './UnidentifiedDepartmentCard.module.scss';

export type InitialFormValuesT = {
  newOrModify: {
    +nameField: ?string,
    +departmentSelection?: { label: string, value: string }
  }
};
export type UnidentifiedDepartmentT = {
  namePath: string[],
  userCount: ?number
};

type OwnPropsT = {
  enterpriseId: ?string,
  unidentifiedDepartment: UnidentifiedDepartmentT,
  index: number
};

type StatePropsT = {
  form: string,
  initialValues: InitialFormValuesT,
  departmentIdTree: IdTreeT,
  departmentName: string,
  departmentSelection: { label: string, value: string },
  selectByDepartmentPath: string => ImportDepartmentItemT | {},
  unidentifiedDepartment: UnidentifiedDepartmentT,
  currentUser: CurrentUserStateT,
  departmentByNamePath: *,
  selectedDepartment: *
};

type DispatchPropsT = {
  createDepartment: typeof departmentOps.createDepartment,
  setImportDepartment: CreateSetDepartmentItemFnT,
  unsetImportDepartment: CreateUnsetDepartmentItemFnT
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  // $FlowFixMe
  ...FormProps,
  ...$Exact<WithTranslationProps>
|};

export type SelectionT = 'SELECT' | 'CREATE' | 'REPLACE' | 'READY';

type StateT = {
  oldSelection: SelectionT,
  selection: SelectionT,
  title: string
};

export class UnidentifiedDepartmentCard extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.renderDepartmentInfo = this.renderDepartmentInfo.bind(this);
    this.renderNewDepartment = this.renderNewDepartment.bind(this);
    this.renderReplaceDepartment = this.renderReplaceDepartment.bind(this);
    this.renderReadyDepartment = this.renderReadyDepartment.bind(this);
    this.validateDepartmentName = this.validateDepartmentName.bind(this);
    this.createParentDepartments = this.createParentDepartments.bind(this);
    this.setDepartment = this.setDepartment.bind(this);
    this.state = {
      oldSelection: 'SELECT',
      selection: 'SELECT',
      title: ''
    };
  }

  setDepartment: (ImportDepartmentTypeT, string) => void;

  setDepartment(status: ImportDepartmentTypeT, title: string) {
    const {
      departmentName,
      departmentSelection,
      setImportDepartment,
      unidentifiedDepartment
    } = this.props;
    this.setState({
      selection: 'READY',
      title
    });
    setImportDepartment({
      status,
      name: departmentName,
      path: unidentifiedDepartment.namePath.join('/'),
      departmentSelection: departmentSelection || null
    });
  }

  validateDepartmentName: string => ?string;

  validateDepartmentName(value: string): ?string {
    return (
      fieldValidators.requiredValidator(value, this.props.t('generic.validators.required')) ||
      fieldValidators.departmentNameFieldValidator(
        value,
        this.props.t('generic.validators.departmentName')
      )
    );
  }

  createParentDepartments: *;

  async createParentDepartments() {
    const {
      unidentifiedDepartment,
      enterpriseId,
      createDepartment,
      currentUser,
      departmentByNamePath
    } = this.props;

    let previousDepartmentId;
    const promises = unidentifiedDepartment.namePath.slice(0, -1).map((name, i) => {
      const foundDepartment = departmentByNamePath(unidentifiedDepartment.namePath.slice(0, i + 1));
      if (!foundDepartment) {
        return createDepartment(
          enterpriseId,
          name,
          i === 0 ? null : previousDepartmentId,
          createCsrfHeader(currentUser)
        ).then(data => {
          previousDepartmentId = data.id;
        });
      }
      previousDepartmentId = foundDepartment.id;
      return Promise.resolve();
    });
    await Promise.all(promises);
  }

  renderDepartmentInfo: *;

  renderDepartmentInfo() {
    const { t, unidentifiedDepartment } = this.props;
    const departmentName = R.last(unidentifiedDepartment.namePath);
    return (
      <div id="department-info" className={styles['inner-container']}>
        <div className={styles.icon} />
        <h3>
          <Truncate lines={1}>
            <span>
              &quot;
              {departmentName}
              &quot;{' '}
            </span>
          </Truncate>
          <Tooltip>
            <div className={styles['tooltip-header']}>
              {t('importUsers.unidentifiedDepartmentCard.tooltipHeader')}
            </div>
            <div className={styles['tooltip-text']}>
              {unidentifiedDepartment.namePath.map((segment, index, arr) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${segment}-${index}`}>
                  {index > 0 && <span className={styles['tooltip-divider']}> / </span>}
                  <span
                    className={classNames({
                      [styles['tooltip-segment']]: true,
                      [styles['tooltip-segment-last']]: index === arr.length - 1
                    })}
                  >
                    {segment}
                  </span>
                </span>
              ))}
            </div>
          </Tooltip>
        </h3>
        <p>
          <Truncate lines={2}>
            <span>
              {t('importUsers.unidentifiedDepartmentCard.text', {
                count:
                  unidentifiedDepartment.userCount != null ? unidentifiedDepartment.userCount : '-'
              })}
            </span>
          </Truncate>
        </p>
        <div className={styles.selections}>
          <p>{t('importUsers.unidentifiedDepartmentCard.selections.text')}</p>
          <button
            id="create-button"
            onClick={async () => {
              this.setState({ selection: 'CREATE' });
              this.createParentDepartments();
            }}
          >
            {t('importUsers.unidentifiedDepartmentCard.selections.createNewButton')}
          </button>
          <button
            id="replace-button"
            onClick={() => {
              this.setState({ selection: 'REPLACE' });
            }}
          >
            {t('importUsers.unidentifiedDepartmentCard.selections.replaceButton')}
          </button>
          <button
            id="do-not-import-button"
            onClick={() =>
              this.setDepartment(
                'ignore',
                t('importUsers.unidentifiedDepartmentCard.readyDepartment.doNotImport', {
                  departmentName
                })
              )
            }
          >
            {t('importUsers.unidentifiedDepartmentCard.selections.doNotImportButton')}
          </button>
        </div>
      </div>
    );
  }

  renderNewDepartment: *;

  renderNewDepartment() {
    const { t, departmentName } = this.props;
    return (
      <div id="new-department" className={styles['inner-container']}>
        <div className={styles.icon} />
        <h3>{t('importUsers.unidentifiedDepartmentCard.createDepartment.title')}</h3>
        <div>
          <FormSection id="modify-section" name="newOrModify">
            <Field
              id="department-name-field"
              label={t('importUsers.unidentifiedDepartmentCard.createDepartment.nameLabel')}
              name="nameField"
              component={InputField}
              validate={this.validateDepartmentName}
            />

            <Field
              id="department-selection-new"
              label={t('importUsers.unidentifiedDepartmentCard.createDepartment.parentTitle')}
              name="departmentSelection"
              options={this.props.departmentIdTree}
              // $FlowFixMe
              component={props => (
                <IdTreeCombobox
                  hasRootOption
                  rootOptionKey={ROOT_DEPARTMENT_KEY}
                  {...props}
                  disabled={
                    !this.props.departmentIdTree || this.props.departmentIdTree.length === 0
                  }
                  className={styles['department-browser']}
                  renderItem={p => <DepartmentComboboxItem {...p} />}
                />
              )}
            />
          </FormSection>
        </div>
        <div className={styles['button-row']}>
          <CancelButton
            id="return-button"
            onClickAction={() => {
              this.setState({ selection: 'SELECT' });
            }}
            label={t('importUsers.unidentifiedDepartmentCard.return')}
          />
          <ActionButton
            id="ready-button"
            className={styles['ready-button']}
            label={t('importUsers.unidentifiedDepartmentCard.ready')}
            disabled={this.validateDepartmentName(departmentName) !== undefined}
            onClickAction={() =>
              this.setDepartment(
                'create',
                t('importUsers.unidentifiedDepartmentCard.readyDepartment.newDepartment', {
                  departmentName
                })
              )
            }
          />
        </div>
      </div>
    );
  }

  renderReplaceDepartment: *;

  renderReplaceDepartment() {
    const { departmentName, t, selectedDepartment } = this.props;
    return (
      <div id="replace-department" className={styles['inner-container']}>
        <div className={styles.icon} />
        <h3>{t('importUsers.unidentifiedDepartmentCard.replaceDepartment.title')}</h3>
        <p>{t('importUsers.unidentifiedDepartmentCard.replaceDepartment.smallTitle')}</p>
        <div className={styles['input-row']}>
          <FormSection id="modify-section" name="newOrModify">
            <Field
              id="department-selection-replace"
              label={t('importUsers.unidentifiedDepartmentCard.replaceDepartment.departmentTitle')}
              name="departmentSelection"
              options={this.props.departmentIdTree}
              // $FlowFixMe
              component={props => (
                <IdTreeCombobox
                  {...props}
                  disabled={
                    !this.props.departmentIdTree || this.props.departmentIdTree.length === 0
                  }
                  className={styles['department-browser']}
                  renderItem={p => <DepartmentComboboxItem {...p} />}
                />
              )}
            />
          </FormSection>
        </div>
        <div className={styles['button-row']}>
          <CancelButton
            id="return-button"
            onClickAction={() => {
              this.setState({ selection: 'SELECT' });
            }}
            label={t('importUsers.unidentifiedDepartmentCard.return')}
          />
          <ActionButton
            id="ready-button"
            className={styles['ready-button']}
            label={t('importUsers.unidentifiedDepartmentCard.ready')}
            onClickAction={() => {
              this.setDepartment(
                'replace',
                t('importUsers.unidentifiedDepartmentCard.readyDepartment.replaceDepartment', {
                  oldDepartmentName: departmentName,
                  newDepartmentName: selectedDepartment ? selectedDepartment.name : ''
                })
              );
            }}
          />
        </div>
      </div>
    );
  }

  renderReadyDepartment: *;

  renderReadyDepartment(title: string) {
    const { oldSelection } = this.state;
    const { t, unidentifiedDepartment } = this.props;
    const departmentPathId = unidentifiedDepartment.namePath.join('/');
    const departmentStatus = this.props.selectByDepartmentPath(departmentPathId);
    const ignoreDepartment = departmentStatus && departmentStatus.status === 'ignore';
    return (
      <div id="ready-department" className={styles['inner-container']}>
        {ignoreDepartment ? (
          <img className={styles.checkmark} src="/do-not-import.svg" alt="do not import" />
        ) : (
          <img className={styles.checkmark} src="/checkmark green.svg" alt="successful" />
        )}
        <div className={styles['info-container']}>{title}</div>
        <div className={styles['link-row']}>
          {!ignoreDepartment && (
            <LinkButton
              id="modify-user-import-department-button"
              className={styles['link-button']}
              label={t('importUsers.unidentifiedDepartmentCard.readyDepartment.modify')}
              onClickAction={() => {
                this.setState({ selection: oldSelection });
                this.props.unsetImportDepartment(departmentPathId);
              }}
            />
          )}
        </div>
        <div className={styles['link-row']}>
          <LinkButton
            id="return-from-user-import-button"
            className={styles['link-button']}
            onClickAction={() => {
              this.setState({ selection: 'SELECT' });
              this.props.unsetImportDepartment(departmentPathId);
            }}
            label={t('importUsers.unidentifiedDepartmentCard.readyDepartment.return')}
          />
        </div>
      </div>
    );
  }

  render() {
    const { selection, title } = this.state;
    return (
      <div className={styles.container}>
        {selection === 'SELECT' && this.renderDepartmentInfo()}
        {selection === 'CREATE' && this.renderNewDepartment()}
        {selection === 'REPLACE' && this.renderReplaceDepartment()}
        {selection === 'READY' && this.renderReadyDepartment(title)}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const formName = `unidentifiedDepartment_${ownProps.index}`;
  const parentDepartment = selectDepartments.parentDepartmentByNamePath(
    state,
    ownProps.enterpriseId,
    ownProps.unidentifiedDepartment.namePath
  );
  const selectFromForm = formValueSelector(formName);
  const departmentSelection = selectFromForm(state, 'newOrModify.departmentSelection');

  return {
    form: formName,
    initialValues: {
      newOrModify: {
        nameField: R.last(ownProps.unidentifiedDepartment.namePath),
        ...(parentDepartment
          ? { departmentSelection: { value: parentDepartment.id, label: parentDepartment.name } }
          : {})
      }
    },
    departmentIdTree: ownProps.enterpriseId
      ? state.entities.department.idTrees[ownProps.enterpriseId]
      : [],
    departmentName: selectFromForm(state, 'newOrModify.nameField'),
    departmentSelection,
    selectByDepartmentPath: departmentPath =>
      userImportUiSelectors.selectByDepartmentPath(state, departmentPath),
    selectedDepartment:
      state.entities.department.byId[departmentSelection ? departmentSelection.value : ''],
    unidentifiedDepartment: ownProps.unidentifiedDepartment,
    currentUser: state.currentUser,
    departmentByNamePath: (namePath: string[]) =>
      departmentSelectors.departmentByNamePath(
        state,
        state.currentUser.currentEnterprise.id,
        namePath
      )
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setImportDepartment: userimportUiActions.createSetDepartmentItem,
      unsetImportDepartment: userimportUiActions.createUnsetDepartmentItem,
      createDepartment: departmentOps.createDepartment
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true
  })
)(UnidentifiedDepartmentCard);
