// @flow

import React, { type Element, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import type { AxiosPromise } from 'axios';
import axios, { CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/Button/ActionButton';
import { update } from '../../../ducks/entities/acd/acdOperations';
import {
  buildAcdPatchPayload,
  convertCsvStringToList,
  uniqueNonEmptyNumberList
} from './importUtils';
import type {
  XlsxRowDataT,
  ImportOptionsT,
  ImportOptionTypeT,
  ImportOptionsItemT
} from './importUtils';
import type { InternalAddressT } from '../../../ducks/entities/acd/acdTypes';
import useCalendars from '../calendar/useCalendars';
import ImportServiceHeaderRow from './ImportServiceHeaderRow';
import ImportServiceRow from './ImportServiceRow';
import useRequestToken from './useRequestToken';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './ImportServicesTable.module.scss';

export type PropsT = {|
  enterpriseId: string,
  dataToImport: XlsxRowDataT[],
  fileMetaData: string[]
|};

type ImportStatusT = {|
  message?: string,
  inProgress: boolean,
  error?: string
|};

type ImportStatusesT = {
  [addressNumber: string]: ImportStatusT
};

const getInternalAddress = async (enterpriseId: string, addressNumber: string) => {
  const response: AxiosPromise<?InternalAddressT> = axios.get(
    `/api/v1/enterprises/${enterpriseId}/internaladdress?addressNumber=${addressNumber}`
  );
  const { data } = await response;
  return data;
};

const getInternalAddresses = async (
  enterpriseId: string,
  addressNumbers: string[],
  errorSupplier
) => {
  const internalAddresses = (addressNumbers || []).map(async addressNumber => {
    try {
      return {
        name: addressNumber,
        result: await getInternalAddress(enterpriseId, addressNumber)
      };
    } catch (err) {
      throw new Error(errorSupplier(addressNumber));
    }
  });
  const allData: { name: string, result: ?InternalAddressT }[] = await Promise.all(
    internalAddresses
  );
  // $FlowFixMe Ramda types missing
  return R.mergeAll(allData.map(r => r && r.name && { [r.name]: r.result }));
};

// eslint-disable-next-line flowtype/no-weak-types
const createInitialImportOptions = (jsonArray: XlsxRowDataT[]): ImportOptionsT => {
  const result = jsonArray.reduce(
    (options, { addressNumber }) => ({
      ...options,
      [(addressNumber: string)]: {
        includeMembers: true,
        includeCalendar: true,
        includeForwardings: true,
        includeOther: true
      }
    }),
    {}
  );
  return {
    ...result,
    all: {
      includeMembers: true,
      includeCalendar: true,
      includeForwardings: true,
      includeOther: true
    }
  };
};

const ImportServicesTable = (props: PropsT): Element<'div'> => {
  const { enterpriseId, fileMetaData, dataToImport } = props;

  // State
  const [importStatuses, setImportStatuses] = useState<ImportStatusesT>({});
  const [importOptions, setImportOptions] = useState<ImportOptionsT>({});
  const requestCancelTokenSource: CancelTokenSource = useRequestToken();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  useEffect(() => {
    setImportOptions(createInitialImportOptions(dataToImport));
    return () => {};
  }, [dataToImport]);

  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const { calendars, isLoadingCalendar } = useCalendars(enterpriseId);

  const updateHeaderOptions = (type: ImportOptionTypeT): ImportOptionsT => {
    // change appropriate selection for all header and content rows
    return R.map(allOrAddressNumberValue => {
      // $FlowFixMe
      return R.mapObjIndexed((selectedValue, typeKey) => {
        return type === typeKey ? !importOptions.all[typeKey] : selectedValue;
      }, allOrAddressNumberValue);
    }, importOptions);
  };

  const updateContentOptions = (target: string, type: ImportOptionTypeT): ImportOptionsT => {
    // change appropriate selection for the specific content row
    const otherOptions = R.mapObjIndexed((selectionObjValue, addressNumberKey) => {
      return target === addressNumberKey
        ? { ...selectionObjValue, [(type: string)]: !selectionObjValue[type] }
        : selectionObjValue;
    }, R.omit(['all'], importOptions));

    // then, if all content rows have either all or not
    const allSelected = R.all(
      (value: ImportOptionsItemT) => !!value[type],
      // $FlowFixMe
      Object.values(otherOptions)
    );

    // we de/select the appropriate header checkbox
    let allOptions = importOptions.all;
    if (allSelected) {
      allOptions = {
        ...allOptions,
        [(type: string)]: true
      };
    } else {
      allOptions = {
        ...allOptions,
        [(type: string)]: false
      };
    }

    return {
      ...otherOptions,
      all: allOptions
    };
  };

  const updateImportOptions = (target: string, type: ImportOptionTypeT) => {
    if (target === 'all') {
      setImportOptions(updateHeaderOptions(type));
    } else {
      // eslint-disable-next-line
      setImportOptions(updateContentOptions(target, type));
    }
  };

  const updateService = async (data: XlsxRowDataT) => {
    const groupMembers = convertCsvStringToList(data.members);
    const addressNumbers = [
      data.addressNumber,
      importOptions[data.addressNumber].includeOther ? data.overflowGroup : '',
      ...(importOptions[data.addressNumber].includeMembers ? groupMembers : []),
      ...(importOptions[data.addressNumber].includeForwardings
        ? [data.closingFwd, data.noOperatorFwd, data.busyFwd, data.goodByeFwd]
        : [])
    ];
    const filteredAddressNumbers = uniqueNonEmptyNumberList(addressNumbers);
    let internalIds = [];
    try {
      internalIds = await getInternalAddresses(enterpriseId, filteredAddressNumbers, n =>
        t('importServices.internalAddressError', { addressNumber: n })
      );
    } catch (err) {
      setImportStatuses({
        ...importStatuses,
        [data.addressNumber]: {
          error: `${err}`,
          inProgress: false
        }
      });
      return new Promise(() => {});
    }
    const patchPayload = buildAcdPatchPayload(
      data,
      internalIds,
      importOptions[data.addressNumber],
      currentUser.featureFlags
    );
    const serviceId = internalIds[data.addressNumber].id;
    // eslint-disable-next-line no-return-await
    return await dispatch(
      update(
        enterpriseId,
        data.type,
        serviceId,
        // $FlowFixMe patch payload typing requires improvements
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  const hasValidCalendarName = (data: XlsxRowDataT) => {
    if (!isLoadingCalendar && calendars) {
      const calendarName = data ? data.openHours : '';
      const calendarNames: Set<string> = new Set(calendars.map(c => c.name));

      return calendarNames.has(calendarName);
    }
    return true;
  };

  const hasCompleteData = (row: XlsxRowDataT) => {
    if (row.type === 'ACD_SWITCHBOARD' || row.type === 'ACD_CUSTOMER_SERVICE') {
      return (row.queueSize || '').match(/^\d+$/);
    }
    return false;
  };

  const importService = async (data: XlsxRowDataT) => {
    const statuses = {
      ...importStatuses,
      [data.addressNumber]: {
        message: t('importServices.status.import'),
        inProgress: true
      }
    };

    setImportStatuses(statuses);
    try {
      const result = await updateService(data);
      setImportStatuses({
        ...importStatuses,
        [data.addressNumber]: {
          message: result ? t('importServices.status.done') : t('importServices.status.error'),
          inProgress: false
        }
      });
    } catch (err) {
      console.error(err);
      if (statuses[data.addressNumber] && statuses[data.addressNumber].inProgress) {
        setImportStatuses({
          ...importStatuses,
          [data.addressNumber]: {
            error: t('importServices.status.error'),
            inProgress: false
          }
        });
      }
    }
  };

  const importAll = async () => {
    // eslint-disable-next-line
    for (const dataRow of dataToImport) {
      await importService(dataRow); // eslint-disable-line
    }
  };

  const rowStatusLine = (dataRow: XlsxRowDataT): string => {
    const addressNumberError =
      importStatuses && importStatuses[dataRow.addressNumber]
        ? R.path([dataRow.addressNumber, 'error'], importStatuses) ||
          R.path([dataRow.addressNumber, 'message'], importStatuses)
        : '';

    const incompleteDataError =
      !hasCompleteData(dataRow) && t('importServices.errorIncompleteData');

    const calendarValidationError =
      !hasValidCalendarName(dataRow) && hasCompleteData(dataRow)
        ? t('importServices.errorCalendarNotFound', {
            calendarName: dataRow.openHours
          })
        : '';

    return addressNumberError || incompleteDataError || calendarValidationError;
  };
  if (dataToImport && dataToImport.length > 0 && importOptions.all) {
    return (
      <div className={styles['result-container']}>
        <div>
          {t('importServices.fileDetails', { fileMetaData: fileMetaData.filter(data => data) })}
        </div>
        <div className={styles.table}>
          <ImportServiceHeaderRow
            importOptions={importOptions}
            updateImportOptions={(target, type) => updateImportOptions(target, type)}
          />
          {dataToImport.map((dataRow, index) => (
            <ImportServiceRow
              key={`import-service-row-${dataRow.addressNumber}`}
              importOptions={importOptions}
              dataRow={dataRow}
              index={index}
              onRowClick={d => importService(d)}
              isLoading={!!R.path([dataRow.addressNumber, 'inProgress'], importStatuses)}
              isDisabled={!hasCompleteData(dataRow) || !hasValidCalendarName(dataRow)}
              rowStatusLine={rowStatusLine(dataRow)}
              updateImportOptions={(target, type) => updateImportOptions(target, type)}
            />
          ))}
        </div>
        <ActionButton
          label={t('importServices.importAllButton')}
          className={styles['import-all-button']}
          id="import-service-all"
          onClickAction={importAll}
          loading={R.any(
            dataRow => R.path(['inProgress'], dataRow) || false,
            Object.values(importStatuses)
          )}
        />
      </div>
    );
  }

  return <div />;
};

export default ImportServicesTable;
