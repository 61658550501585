// @flow

import React from 'react';
import IconLockRegular from '@design-system/component-library/src/components/Icon/lib/IconLockRegular';
import styles from './ProtectedFieldsIndicator.module.scss';

const ProtectedFieldsIndicator = () => {
  return (
    <div className={styles['contact-hidden-container']}>
      <IconLockRegular size="s" />
    </div>
  );
};

ProtectedFieldsIndicator.defaultProps = {};

export default ProtectedFieldsIndicator;
