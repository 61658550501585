// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as yup from 'yup';
import { CancelToken, CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { ReactComponent as QueueIcon } from '../../../../../assets/callflow/details/queue-small.svg';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import IntegerField from '../../../components/edit/children/IntegerField';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import {
  createIvrPatchPayload,
  DEFAULT_QUEUE_SIZE,
  getMaxGroupQueueSize,
  MAX_QUEUE_SIZE,
  MIN_QUEUE_SIZE
} from '../../../components/edit/CallflowPropertyUtils';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import { convertMinutesToSeconds, convertSecondsToMinutes } from '../../../../../utils/timeutils';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import AcdAudioFieldList from '../../../components/edit/children/audio/AcdAudioFieldList';
import { stripExtension } from '../../../../../helpers';
import ToggleField from '../../../components/edit/children/ToggleField';
import { removeOverflowGroupIfNeeded } from './EditAcdAgent';

type PropsT = {|
  callflowId: string
|};

type FormT = {
  queueSize: number,
  maxDurationInQueue: number,
  waitingMusicMsg: AudioFieldT<PropertyAudioT>,
  queueSizeNotification: boolean,
  queueTimeNotification: boolean,
  waitingAnnounceMsg: AudioFieldT<PropertyAudioT>,
  periodicityTimer: number,
  waitingMusicPlaylist: ?AudioFieldT<PropertyAudioT>,
  waitingAnnouncementPlaylist: ?AudioFieldT<PropertyAudioT>
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

// $FlowFixMe: TODO: fix
export const acdQueueSchema = yup.object().shape({
  queueSize: yup
    .number()
    .integer()
    .min(MIN_QUEUE_SIZE)
    .max(MAX_QUEUE_SIZE)
    .default(DEFAULT_QUEUE_SIZE)
    .required(),
  maxDurationInQueue: yup
    .number()
    .integer()
    .min(0)
    .max(120)
    .default(20)
    .required(),
  periodicityTimer: yup
    .number()
    .integer()
    .min(20)
    .max(1200)
    .default(60)
    .required()
});
const EditAcdQueue = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');
  const [acdQueueSchemaFixed, setAcdQueueSchemaFixed] = useState();
  const [formKey, setFormKey] = useState(0);
  const generateSchema = async () => {
    const maxGroupQueueSize = await getMaxGroupQueueSize(acdData.enterpriseId);
    setAcdQueueSchemaFixed(
      // $FlowFixMe
      yup.object().shape({
        // $FlowFixMe
        ...acdQueueSchema.fields,
        queueSize: yup
          .number()
          .integer()
          .min(1)
          .max(maxGroupQueueSize)
          .default(5)
          .required()
      })
    );
  };

  const initialProperties = {
    maxDurationInQueue: convertSecondsToMinutes(R.path(['maxDurationInQueue'], acdCallCenter)),
    waitingMusicMsg: acdAudioConverter.convertToAudioPropertyField(
      'WaitingMusicMsg',
      R.path(['audios', 'waitingMusicMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.waitingMusicMsg
    ),
    queueSizeNotification: R.path(['queueSizeNotification', 'value'], ivrAcdCallCenter), // TODO: use servicesSettings
    queueTimeNotification: R.path(['queueTimeNotification', 'value'], ivrAcdCallCenter), // TODO: use servicesSettings
    waitingAnnounceMsg: acdAudioConverter.convertToAudioPropertyField(
      'WaitingAnnounceMsg',
      R.path(['audios', 'waitingAnnounceMsg', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.waitingAnnounceMsg
    ),
    periodicityTimer: R.path(['periodicityTimer'], acdCallCenter),
    waitingMusicPlaylist: ivrAcdCallCenter.waitingMusicPlaylist
      ? acdAudioConverter.convertToAudioPropertyField(
          'WaitingMusicPlaylist',
          R.path(['waitingMusicPlaylist', 'value'], ivrAcdCallCenter),
          undefined,
          R.path(['waitingMusicPlaylist', 'level'], ivrAcdCallCenter)
        )
      : null,
    waitingAnnouncementPlaylist: ivrAcdCallCenter.waitingMusicPlaylist
      ? acdAudioConverter.convertToAudioPropertyField(
          'WaitingAnnouncementPlaylist',
          R.path(['waitingAnnouncementPlaylist', 'value'], ivrAcdCallCenter),
          undefined,
          R.path(['waitingAnnouncementPlaylist', 'level'], ivrAcdCallCenter)
        )
      : null
  };
  const initialFormValues: FormT = {
    queueSize: acdData.queueSize,
    ...initialProperties
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    generateSchema();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormKey(new Date().getTime());
  }, [acdQueueSchemaFixed]); // eslint-disable-line react-hooks/exhaustive-deps

  const sanitizeAudioFilenames = patchPayload => {
    const newPayload = { ...patchPayload };

    const acdCallCtr = newPayload?.ivrProperties?.acdCallCenter;

    // $FlowFixMe
    if (acdCallCtr?.waitingAnnouncementPlaylist?.value) {
      acdCallCtr.waitingAnnouncementPlaylist.value = stripExtension(
        acdCallCtr.waitingAnnouncementPlaylist.value
      );
    }
    return newPayload;
  };

  const onSubmit = async formData => {
    const maxDurationInQueueInSeconds = convertMinutesToSeconds(formData.maxDurationInQueue);
    let patchPayload = createIvrPatchPayload(
      {
        queueSize: formData.queueSize,
        ...removeOverflowGroupIfNeeded(acdData.ringPattern, acdData.overflowGroup)
      },
      formData,
      Object.keys(initialProperties).filter(prop => prop !== 'maxDurationInQueue'),
      formData.maxDurationInQueue !== initialProperties.maxDurationInQueue
        ? [
            {
              maxDurationInQueue: {
                level: 'Extension',
                value: maxDurationInQueueInSeconds === 0 ? 1 : maxDurationInQueueInSeconds
              }
            }
          ]
        : []
    );

    if (currentUser?.featureFlags?.includes('FEATURE-GENERIC-API')) {
      patchPayload = sanitizeAudioFilenames({
        ...patchPayload,
        servicesSettings: {
          acdCallCenter: {
            waitingMusicPlaylist: formData.waitingMusicPlaylist?.audioPlayData?.filename,
            waitingAnnouncementPlaylist:
              formData.waitingAnnouncementPlaylist?.audioPlayData?.filename
          }
        }
      });
      // $FlowFixMe
      patchPayload.ivrProperties.acdCallCenter.waitingMusicPlaylist.level = 'Extension';
      // $FlowFixMe
      patchPayload.ivrProperties.acdCallCenter.waitingAnnouncementPlaylist.level = 'Extension';
    } else {
      delete patchPayload?.ivrProperties?.acdCallCenter?.waitingMusicPlaylist;
      delete patchPayload?.ivrProperties?.acdCallCenter?.waitingAnnouncementPlaylist;
    }

    const failedAudioUploads = await uploadAudios(
      formData,
      audioRequestCancelTokenSource,
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }

    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<QueueIcon />}
      title={t('callflows.editAcdQueue.title')}
      description={t('callflows.editAcdQueue.description')}
      defaultValues={initialFormValues}
      validationSchema={acdQueueSchemaFixed}
      onSaveForm={onSubmit}
      key={formKey}
    >
      <IntegerField
        field="queueSize"
        maxLength={3}
        title={t('callflows.editAcdQueue.queueSizeTitle')}
        description={t('callflows.editAcdQueue.queueSizeInfo')}
        inputDescription={t(
          'callflows.editAcdQueue.queueSizeInputTitle',
          getIntegerFieldLimits(
            // $FlowFixMe: TODO: fix
            acdQueueSchemaFixed && acdQueueSchemaFixed.fields
              ? acdQueueSchemaFixed.fields.queueSize
              : // $FlowFixMe: TODO: fix
                acdQueueSchema.fields.queueSize
          )
        )}
        postFixDescription={t('callflows.editAcdQueue.queueSizeInputAfter')}
        tooltip={t(
          'callflows.viewAcdQueue.queueSizeTooltip',
          getIntegerFieldLimits(
            acdQueueSchemaFixed && acdQueueSchemaFixed.fields
              ? acdQueueSchemaFixed.fields.queueSize
              : // $FlowFixMe: TODO: fix
                acdQueueSchema.fields.queueSize
          )
        )}
        errorMessage={t(
          'integerField.error',
          getIntegerFieldLimits(
            acdQueueSchemaFixed && acdQueueSchemaFixed.fields
              ? acdQueueSchemaFixed.fields.queueSize
              : // $FlowFixMe: TODO: fix
                acdQueueSchema.fields.queueSize
          )
        )}
      />
      <IntegerField
        field="maxDurationInQueue"
        title={t('callflows.editAcdQueue.maxDurationInQueueTitle')}
        description={t('callflows.editAcdQueue.maxDurationInQueueInfo')}
        inputDescription={t('callflows.editAcdQueue.maxDurationInQueueInputTitle')}
        postFixDescription={t('callflows.editAcdQueue.maxDurationInQueueInputAfter')}
        tooltip={t(
          'callflows.viewAcdQueue.maxDurationInQueueTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdQueueSchema.fields.maxDurationInQueue)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdQueueSchema.fields.maxDurationInQueue)
        )}
      />
      {!((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
        <AcdAudioField
          field="waitingMusicMsg"
          title={t('callflows.editAcdQueue.queueMusicTitle')}
        />
      ) : null}
      <ToggleField
        field="queueSizeNotification"
        elementId="queueSizeNotification"
        label={t('callflows.editAcdQueue.showQueueSize')}
      />
      <ToggleField
        field="queueTimeNotification"
        elementId="queueTimeNotification"
        label={t('callflows.editAcdQueue.showQueueTime')}
      />
      {!((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
        <AcdAudioField
          field="waitingAnnounceMsg"
          title={t('callflows.editAcdQueue.temporaryMusicTitle')}
          description={t('callflows.editAcdQueue.temporaryMusicDescription')}
        />
      ) : (
        <div>
          <AcdAudioFieldList
            field="waitingMusicPlaylist"
            title={t('callflows.editAcdQueue.waitingMusicPlaylistTitle')}
          />
          <AcdAudioFieldList
            field="waitingAnnouncementPlaylist"
            title={t('callflows.editAcdQueue.waitingAnnouncementPlaylistTitle')}
          />
        </div>
      )}
      <IntegerField
        field="periodicityTimer"
        maxLength={4}
        title=""
        inputDescription={t(
          'callflows.editAcdQueue.loopTimeInputTitle',
          // $FlowFixMe
          getIntegerFieldLimits(acdQueueSchema.fields.periodicityTimer)
        )}
        postFixDescription={t('callflows.editAcdQueue.loopTimeInputAfter')}
        showMinutes
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdQueueSchema.fields.periodicityTimer)
        )}
      />
    </EditCallflowDetails>
  );
};

export default EditAcdQueue;
