// @flow

import React, { Component, type Element } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { CancelToken, CancelTokenSource } from 'axios';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../commonTypes';
import { StepsIndicator } from '../../../components/StepsIndicator';
import Dismiss from '../../../components/Button/Dismiss';
import ActionButton from '../../../components/Button/ActionButton';
import CancelButton from '../../../components/Button/CancelButton';
import type {
  ImportAnalysisSheetStateT,
  ImportUsersT,
  ImportDepartmentItemT,
  ImportUserT,
  ImportUserStatusT,
  ImportInternalUserEntityT,
  ImportExternalUserEntityT
} from '../../../ducks/ui/userImport/userImportUiTypes';
import UploadStep from './UploadStep';
import DepartmentsAndLocationsStep from './DepartmentsAndLocationsStep';
import ProcessStep from './ProcessStep';
import SuccessStep from './SuccessStep';
import ErrorStep from './ErrorStep';
import type { UpdateT } from '../../../ducks/entities/user/userOperations';
import { operations as userOps } from '../../../ducks/entities/user';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import type { ExternalUserEntityT, UserEntityT } from '../../../ducks/entities/user/userTypes';
import { asyncPool } from '../../../helpers';
import {
  actionCreators as userImportUiActions,
  selectors as importSelectors
} from '../../../ducks/ui/userImport';
import { operations as departmentOps } from '../../../ducks/entities/department';
import type { DepartmentPayloadEntityT } from '../../../ducks/entities/department/departmentTypes';
import Notifications from '../../../components/Notifications/Notifications';
import * as departmentSelectors from '../../../ducks/entities/department/departmentSelectors';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import { pushAnalyticsEvent } from '../../../utils/analyticsHandler';
import { IMPORT_CONTACTS } from '../../../matomo_constants';

import styles from './ImportUsers.module.scss';

export type StepT =
  | { index: 0, name: 'choose file' }
  | { index: 1, name: 'process departments and locations' }
  | { index: 2, name: 'perform import' }
  | { index: 3, name: 'success view' }
  | { index: 4, name: 'error view' };

type OwnPropsT = { endImportUsers: () => * };

type StatePropsT = {
  currentUser: CurrentUserStateT,
  sheets: ImportAnalysisSheetStateT[],
  allDepartmentsHaveSelectedAction: boolean,
  unidentifiedDepartmentActions: {
    [string]: ImportDepartmentItemT
  },
  departmentByNamePath: *
};

type DispatchPropsT = {
  retrieveDepartments: (string, CancelToken) => *,
  createDepartment: typeof departmentOps.createDepartment,
  clearImportDepartments: typeof userImportUiActions.createClearImportDepartments,
  resetAnalysis: typeof userImportUiActions.createAnalyzeImportReset,
  update: UpdateT,
  create: (ExternalUserEntityT, CancelToken) => *,
  selectSheet: typeof userImportUiActions.createAnalyzeImportSelectSheet
};
export type SheetsSelectedT = ?('internalOrBothWithNewDepartments' | 'other');
export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export type StateT = {
  currentStep: StepT,
  isContinueDisabled: boolean,
  sheetSelection: SheetsSelectedT,
  maxUsersImported: number,
  usersImported: number,
  departmentsImported: number,
  maxDepartmentsImported: number
};

export class ImportUsers extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      currentStep: { index: 0, name: 'choose file' },
      sheetSelection: undefined,
      isContinueDisabled: true,
      maxUsersImported: 0,
      usersImported: 0,
      departmentsImported: 0,
      maxDepartmentsImported: 0
    };
    this.nextStep = this.nextStep.bind(this);
    this.closeImport = this.closeImport.bind(this);
    this.createDepartments = this.createDepartments.bind(this);
    this.handleUploadStepSuccess = this.handleUploadStepSuccess.bind(this);
    this.handleUploadStepError = this.handleUploadStepError.bind(this);
    this.handleContinueAction = this.handleContinueAction.bind(this);
    this.handleCancelAction = this.handleCancelAction.bind(this);
    this.startImportingUsers = this.startImportingUsers.bind(this);
    this.requestCancelTokenSource = CancelToken.source();
    this.retrieveDepartmentsRequestCancelTokenSource = CancelToken.source();
    this.cancelProcessAction = this.cancelProcessAction.bind(this);
    this.createUserUpdateObject = this.createUserUpdateObject.bind(this);
  }

  componentDidMount() {
    const { currentUser, retrieveDepartments } = this.props;
    const enterpriseId = currentUser.currentEnterprise ? currentUser.currentEnterprise.id : '';
    if (enterpriseId) {
      retrieveDepartments(enterpriseId, this.retrieveDepartmentsRequestCancelTokenSource.token);
    }
    this.props.clearImportDepartments();
  }

  componentWillUnmount() {
    this.requestCancelTokenSource.cancel();
  }

  retrieveDepartmentsRequestCancelTokenSource: CancelTokenSource;

  requestCancelTokenSource: CancelTokenSource;

  nextStep: *;

  nextStep(stepIndex: number): void {
    switch (stepIndex) {
      case 0:
        this.setState({ currentStep: { index: 0, name: 'choose file' } });
        break;
      case 1:
        this.setState({ currentStep: { index: 1, name: 'process departments and locations' } });
        break;
      case 2:
        this.setState({ currentStep: { index: 2, name: 'perform import' } });
        break;
      default:
        this.setState({ currentStep: { index: 0, name: 'choose file' } });
        break;
    }
  }

  createDepartments: void => { [string]: DepartmentPayloadEntityT };

  async createDepartments() {
    const {
      unidentifiedDepartmentActions,
      createDepartment,
      currentUser,
      departmentByNamePath
    } = this.props;
    return R.reduce(
      async (accPromise, department) => {
        const acc = await accPromise;
        if (department.status === 'create' && currentUser.currentEnterprise) {
          const foundDepartment = departmentByNamePath(department.path.split('/'));
          if (!foundDepartment) {
            const createdDepartment = await createDepartment(
              currentUser.currentEnterprise.id,
              department.name,
              R.path(['departmentSelection', 'value'], department) || null,
              createCsrfHeader(currentUser)
            );
            acc[department.path] = createdDepartment;
          } else {
            acc[department.path] = foundDepartment;
          }
        }
        this.setState(state => ({
          departmentsImported: state.departmentsImported + 1
        }));
        return acc;
      },
      Promise.resolve({}),
      R.values(unidentifiedDepartmentActions)
    );
  }

  createUserUpdateObject: (
    { user: ImportUserT, userImportStatus: ImportUserStatusT },
    { [string]: DepartmentPayloadEntityT }
  ) => UserEntityT;

  createUserUpdateObject(
    importUser: { user: ImportUserT, userImportStatus: ImportUserStatusT },
    newDepartments: { [string]: DepartmentPayloadEntityT }
  ) {
    const { unidentifiedDepartmentActions, currentUser } = this.props;
    const normalizeDepartmentPath = (path: ?string): string => {
      const startsWith = R.curry((q: string, str: string): boolean => str.startsWith(q));
      const stripLeft = R.curry((q: string, str: string): string =>
        startsWith(q, str) ? str.slice(q.length) : str
      );
      return stripLeft('/', path || '');
    };
    const departmentPath = normalizeDepartmentPath(
      R.path(['user', 'department', 'name'], importUser)
    );
    const userDepartmentAction = R.path([departmentPath, 'status'], unidentifiedDepartmentActions);
    const userUpdate = {
      // $FlowFixMe
      ...R.dissoc('department', importUser.user),
      enterpriseId: currentUser.currentEnterprise ? currentUser.currentEnterprise.id : ''
    };

    if (userDepartmentAction === 'replace') {
      const departmentId =
        R.path([departmentPath, 'departmentSelection', 'value'], unidentifiedDepartmentActions) ||
        null;
      return R.assocPath(['departmentId'], departmentId, userUpdate);
    }
    if (userDepartmentAction === 'create') {
      const departmentId = departmentPath
        ? R.path([departmentPath, 'id'], newDepartments) || null
        : null;
      return departmentId !== null
        ? R.assocPath(['departmentId'], departmentId, userUpdate)
        : userUpdate;
    }
    if (!userDepartmentAction) {
      const department = this.props.departmentByNamePath(departmentPath.split('/'));
      return {
        ...userUpdate,
        departmentId: R.path(['id'], department) || ''
      };
    }
    return userUpdate;
  }

  importUsers: *;

  async importUsers(usersToImport: ImportUsersT): Promise<void> {
    const { update, create, currentUser } = this.props;
    const newDepartments = await this.createDepartments();
    const importUser = user =>
      new Promise(async resolve => {
        if (user) {
          let result;
          if (user.userImportStatus === 'MODIFY') {
            result = await update(
              this.createUserUpdateObject(user, newDepartments),
              this.requestCancelTokenSource.token,
              createCsrfHeader(currentUser)
            );
          } else if (user.userImportStatus === 'CREATE') {
            result = await create(
              {
                ...user.user,
                enterpriseId: currentUser.currentEnterprise ? currentUser.currentEnterprise.id : ''
              },
              this.requestCancelTokenSource.token,
              createCsrfHeader(currentUser)
            );
          } else {
            this.setState(state => ({
              usersImported: state.usersImported + 1
            }));
            resolve('success');
            return;
          }
          if (result) {
            this.setState(state => ({
              usersImported: state.usersImported + 1
            }));
            resolve('success');
            return;
          }
          this.requestCancelTokenSource.cancel();
        }
        resolve('error');
      });

    const returnValues: *[] = await asyncPool(4, usersToImport, importUser);

    if (returnValues.filter(value => value === 'error').length === 0) {
      this.setState({ currentStep: { index: 3, name: 'success view' } });
    } else {
      this.setState({ currentStep: { index: 4, name: 'error view' } });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  convertInternalUserForImport(user: *): ImportInternalUserEntityT {
    const convertedUser: ImportInternalUserEntityT = {
      id: user.id,
      title: user.title,
      login: user.login,
      duties: user.duties,
      department: user.department,
      additionalExplanations: user.additionalExplanations,
      additionalInfo: user.additionalInfo,
      corporateUserId: user.corporateUserId,
      costCenter: user.costCenter,
      contactInformation: user.contactInformation,
      nickName: user.nickName,
      professionalPostalAddress: user.professionalPostalAddress,
      accessCtrlSystemPersonId: user.accessCtrlSystemPersonId,
      homeNumber: user.homeNumber,
      mobileNumber: user.mobileNumber,
      substitutes: user.substitutes ?? [],
      superiors: user.superiors ?? [],
      assistants: user.assistants ?? [],
      tagNames: user.tagNames ?? [],
      emails: user.emails,
      hideCallerId: user.hideCallerId,
      userType: user.userType,
      addressNumber: user.addressNumber,
      enterpriseId: user.enterpriseId
    };
    return convertedUser;
  }

  // eslint-disable-next-line class-methods-use-this
  convertExternalUserForImport(user: *): ImportExternalUserEntityT {
    const convertedUser: ImportExternalUserEntityT = {
      id: user.id,
      address: user.address,
      defaultPhoneNumber: user.defaultPhoneNumber,
      emails: user.emails,
      enterpriseId: user.enterpriseId,
      faxAddress: user.faxAddress,
      firstName: user.firstName,
      homeNumber: user.homeNumber,
      lastName: user.lastName,
      mobileNumber: user.mobileNumber,
      nickName: user.nickName,
      professionalPhoneNumber: user.professionalPhoneNumber,
      title: user.title,
      website: user.website,
      duties: user.duties,
      additionalExplanations: user.additionalExplanations,
      additionalInfo: user.additionalInfo,
      corporateUserId: user.corporateUserId,
      costCenter: user.costCenter,
      accessCtrlSystemPersonId: user.accessCtrlSystemPersonId,
      userType: user.userType
    };
    return convertedUser;
  }

  startImportingUsers: *;

  startImportingUsers(selection: ?string): void {
    const { sheetSelection } = this.state;
    const { sheets } = this.props;
    if (sheets[0]) {
      pushAnalyticsEvent(`${IMPORT_CONTACTS}${sheets[0].type.toUpperCase()}`);
    }
    this.setState({ currentStep: { index: 2, name: 'perform import' }, usersImported: 0 });
    const allUsers: ImportUsersT[] = sheets.map(sheet => {
      if (
        sheet.type === 'externalUsers' ||
        (sheet.type === 'internalUsers' &&
          ((selection || sheetSelection) === 'internalOrBothWithNewDepartments' ||
            (selection || sheetSelection) === 'other'))
      ) {
        return sheet.type === 'internalUsers' ? sheet.internalUsers : sheet.users;
      }
      return [];
    });
    const flatten = R.reject(
      R.isNil,
      R.flatten(
        // $FlowFixMe
        sheets.map(({ newDepartments }) => (newDepartments != null ? newDepartments : null))
      )
    );
    const newDepartmentList = R.values(
      R.reduce(
        (acc, dep) => ({
          ...acc,
          [R.join('/', dep.path)]: {
            path: dep.path,
            userCount: dep.userCount + (R.path([R.join('/', dep.path), 'userCount'], acc) || 0)
          }
        }),
        {},
        flatten
      )
    );
    if (allUsers.length > 0) {
      const usersToImport = [].concat(...allUsers).map(u => ({
        ...u,
        user: u.user
          ? u.user.userType === 'internalUser'
            ? this.convertInternalUserForImport(u.user)
            : this.convertExternalUserForImport(u.user)
          : null
      }));
      this.setState({
        maxUsersImported: usersToImport.length,
        maxDepartmentsImported: newDepartmentList.length
      });
      this.importUsers(usersToImport);
    }
  }

  closeImport: *;

  closeImport(): void {
    const { resetAnalysis, endImportUsers } = this.props;

    resetAnalysis();
    endImportUsers();
  }

  handleUploadStepSuccess: *;

  handleUploadStepSuccess(): void {
    const { sheets, selectSheet } = this.props;
    this.nextStep(1);
    this.setState({
      isContinueDisabled: false
    });
    let selection;
    selectSheet(0, true);
    if (
      sheets.filter(
        ({ type, newDepartments }) =>
          type === 'internalUsers' && newDepartments && newDepartments.length > 0
      ).length > 0
    ) {
      selection = 'internalOrBothWithNewDepartments';
      this.setState({ sheetSelection: selection });
    } else if (sheets.length > 0) {
      selection = 'other';
      this.setState({ sheetSelection: selection });
    } else {
      this.setState({ sheetSelection: undefined });
    }
    this.handleContinueAction(selection);
  }

  handleUploadStepError: *;

  handleUploadStepError(): void {
    this.setState({
      isContinueDisabled: true
    });
  }

  handleContinueAction: *;

  handleContinueAction(selection: ?string): void {
    const { currentStep, sheetSelection } = this.state;
    if (
      currentStep.name === 'choose file' &&
      (selection || sheetSelection) !== 'internalOrBothWithNewDepartments'
    ) {
      this.nextStep(currentStep.index + 2);
      this.startImportingUsers(selection);
    } else {
      this.nextStep(currentStep.index + 1);
      if (currentStep.name === 'process departments and locations') {
        this.startImportingUsers(selection);
      }
    }
  }

  handleCancelAction: *;

  handleCancelAction(): void {
    const { currentStep } = this.state;
    if (currentStep.name === 'choose file') {
      this.closeImport();
    } else {
      this.setState({ isContinueDisabled: true });
      this.nextStep(currentStep.index - 1);
    }
  }

  cancelProcessAction: *;

  cancelProcessAction(): void {
    const { endImportUsers } = this.props;
    this.requestCancelTokenSource.cancel();
    endImportUsers();
  }

  render(): Element<'div'> {
    const { endImportUsers, t } = this.props;
    const {
      currentStep,
      isContinueDisabled,
      usersImported,
      maxUsersImported,
      departmentsImported,
      maxDepartmentsImported
    } = this.state;
    return (
      <div className={styles.container}>
        <Notifications
          tags={['export-success', 'export-failure']}
          render={({ message, keyId, tag }) => (
            <div className={classnames('ea-notification', styles[tag])} key={keyId} role="status">
              {message}
            </div>
          )}
        />
        <Dismiss id="dismiss-import-users-modal" onClose={this.closeImport} />
        <h3>{t('importUsers.mainTitle')}</h3>
        <div className={styles.steps}>
          <StepsIndicator stepCount={3} currentStep={currentStep.index + 1} />
        </div>
        <div className={styles['step-container']}>
          {currentStep.name === 'choose file' && (
            <UploadStep
              onSuccess={this.handleUploadStepSuccess}
              onError={this.handleUploadStepError}
              closeImport={this.closeImport}
            />
          )}
          {currentStep.name === 'process departments and locations' && (
            <DepartmentsAndLocationsStep />
          )}
          {currentStep.name === 'perform import' && (
            <ProcessStep
              numberOfUsersImported={usersImported}
              maxNumberOfUsersImported={maxUsersImported}
              numberOfDepartmentsImported={departmentsImported}
              maxNumberOfDepartmentsImported={maxDepartmentsImported}
              onCancel={this.cancelProcessAction}
            />
          )}
          {currentStep.name === 'success view' && (
            <SuccessStep
              numberOfUsersImported={usersImported}
              maxNumberOfUsersImported={maxUsersImported}
              numberOfDepartmentsImported={departmentsImported}
              maxNumberOfDepartmentsImported={maxDepartmentsImported}
              onConfirm={endImportUsers}
            />
          )}
          {currentStep.name === 'error view' && (
            <ErrorStep
              numberOfUsersImported={usersImported}
              maxNumberOfUsersImported={maxUsersImported}
              numberOfDepartmentsImported={departmentsImported}
              maxNumberOfDepartmentsImported={maxDepartmentsImported}
              onConfirm={this.startImportingUsers}
              onCancel={endImportUsers}
            />
          )}
        </div>
        {(currentStep.name === 'choose file' ||
          currentStep.name === 'process departments and locations') && (
          <div>
            <ActionButton
              id="import-continue-button"
              label={t('importUsers.continue')}
              onClickAction={() => this.handleContinueAction()}
              disabled={
                isContinueDisabled ||
                (currentStep.name === 'process departments and locations' &&
                  !this.props.allDepartmentsHaveSelectedAction)
              }
            />
            <CancelButton
              id="import-return-button"
              label={t('importUsers.return')}
              onClickAction={this.handleCancelAction}
              className={styles['return-button']}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => {
  const unidentifiedDepartments = importSelectors.selectUnidentifiedDepartments(state);
  const keys = R.keys(state.ui.userImport.importDepartments) || [];
  return {
    currentUser: state.currentUser,
    sheets: state.ui.userImport.importAnalysis.sheets,
    allDepartmentsHaveSelectedAction: keys.length === unidentifiedDepartments.length,
    unidentifiedDepartmentActions: state.ui.userImport.importDepartments,
    departmentByNamePath: (namePath: string[]) =>
      departmentSelectors.departmentByNamePath(
        state,
        state.currentUser.currentEnterprise.id,
        namePath
      )
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrieveDepartments: departmentOps.retrieveCollection,
      createDepartment: departmentOps.createDepartment,
      clearImportDepartments: userImportUiActions.createClearImportDepartments,
      resetAnalysis: userImportUiActions.createAnalyzeImportReset,
      update: userOps.update,
      create: userOps.create,
      selectSheet: userImportUiActions.createAnalyzeImportSelectSheet
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(ImportUsers);
