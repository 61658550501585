// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import configOperations from '../ducks/config/configOperations';

export type EnterpriseSettingsResponseT = {|
  maxMembersInExtensionGroup: ?number,
  maxMembersInUnissonGroup: ?number,
  isLoadingEnterpriseSettings?: boolean
|};

const useEnterpriseSettings = (
  enterpriseId: string | typeof undefined | null
): EnterpriseSettingsResponseT => {
  // Redux
  const dispatch = useDispatch();

  // State
  const [isLoadingEnterpriseSettings, setIsLoadingEnterpriseSettings] = useState<boolean>(true);
  const [maxMembersInExtensionGroup, setMaxMembersInExtensionGroup] = useState<?number>(undefined);
  const [maxMembersInUnissonGroup, setMaxMembersInUnissonGroup] = useState<?number>(undefined);

  const fetchEnterpriseSettings = async () => {
    if (enterpriseId) {
      const res = await dispatch(configOperations.getEnterpriseSettings(enterpriseId));
      setMaxMembersInExtensionGroup(res.maxMembersInExtensionGroup);
      setMaxMembersInUnissonGroup(res.maxMembersInUnissonGroup);
    }
    setIsLoadingEnterpriseSettings(false);
  };

  useEffect(() => {
    fetchEnterpriseSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    maxMembersInExtensionGroup,
    maxMembersInUnissonGroup,
    isLoadingEnterpriseSettings
  };
};

export default useEnterpriseSettings;
