// @flow
/* eslint-disable import/prefer-default-export */
import React, { type Element, Fragment } from 'react';
import { Field, FormSection } from 'redux-form';
import classnames from 'classnames';
import * as R from 'ramda';
import IconWarningRegular from '@design-system/component-library/src/components/Icon/lib/IconWarningRegular';
import IconTickRegular from '@design-system/component-library/src/components/Icon/lib/IconTickRegular';
import type { TranslateT } from '../../../commonTypes';
import FormInput from '../../../components/InputComponent/FormInput';
import FormSite from '../../../components/InputComponent/FormSite';
import { FormIdTreeCombobox } from '../../../components/InputComponent/FormIdTreeCombobox';
import Search from '../../../components/Search/Search';
import type {
  InternalUserEntityT,
  InternalUserStateEntityT,
  UserListElementT
} from '../../../ducks/entities/user/userTypes';
import KeywordInput from '../../../components/InputComponent/KeywordInput';
import ListEdit from '../../../components/Search/ListEdit';
import ResultItem from '../../../components/Search/ResultItem';
import LinkButton from '../../../components/Button/LinkButton';
import { hasValues, NO_VALUE_FIELD_ID } from '../../../helpers';
import Reason from '../../../components/ForwardingsTable/Reason';
import Destination from '../../../components/ForwardingsTable/Destination';
import ActionButton from '../../../components/Button/ActionButton';
import CancelButton from '../../../components/Button/CancelButton';
import Notifications from '../../../components/Notifications/Notifications';
import type { IdTreeT } from '../../../ducks/entities/department/departmentTypes';
import ContactSelector from '../../../components/ContactSelector/ContactSelector';
import UserLocked from '../../../components/UserLocked/UserLocked';
import type { ResultItemT } from '../../../components/Search/ResultItem';
import ForwardingTableCell from '../../callForwardings/userForwardings/ForwardingTableCell';
import UserVoiceMailAudioField from './Internal/UserVoiceMailAudioField';
import type { VoiceMailEntityT } from '../../../ducks/entities/voicemailTypes';
import UploadDialog from '../../../components/UploadDialog';
import { CheckboxField } from '../../../components/InputComponent/ReduxFormField';
import { ReactComponent as LockIcon } from '../../../assets/lock.svg';
import {
  ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH,
  ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
  ADDITIONAL_INFO_MAX_LENGTH,
  CONTACT_INFORMATION_MAX_LENGTH,
  CORPORATE_USER_ID_MAX_LENGTH,
  COST_CENTER_MAX_LENGTH,
  EMAILS_MAX_LENGTH,
  NICKNAME_MAX_LENGTH,
  OFFICE_REFERENCE_ID_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  TITLE_MAX_LENGTH
} from '../../../fieldValidators';
import styles from './Internal/InternalUserDetails.module.scss';

export const DUTIES_MAX_LENGTH = 512;
export const TAG_NAMES_MAX_LENGTH = 500;
export const TAG_NAME_MAX_LENGTH = 50;
export const EMAILS_SP = 'emails';
export const NICKNAME_SP = 'nickName';
export const MOBILE_NUMBER_SP = 'mobileNumber';
export const HOME_NUMBER_SP = 'homeNumber';
export const MOBILE_NUMBERS_SP = 'mobileNumbers';
export const HOME_NUMBERS_SP = 'homeNumbers';
export const COST_CENTER_SP = 'costCenter';
export const DUTIES_SP = 'duties';
export const PROFESSIONAL_POSTAL_ADDRESS_SP = 'professionalPostalAddresses';
export const LOCATION_SP = 'location';
export const ADDITIONAL_INFO_SP = 'additionalInfo';
export const ADDITIONAL_EXPLANATIONS_SP = 'additionalExplanations';
export const CORPORATE_USER_ID_SP = 'corporateUserId';
export const ACCESS_CTRL_SYSTEM_PERSON_ID_SP = 'accessCtrlSystemPersonId';
export const CONTACT_INFORMATION_SP = 'contactInformation';

export const SUBSTITUTES_SP = 'substitutes';
export const SUPERIORS_SP = 'superiors';
export const ASSISTANTS_SP = 'assistants';
export const PSTN_NUMBERS_SP = 'pstnNumbers';
export const PLMN_NUMBERS_SP = 'plmnNumbers';

const emptySection = translate => (
  <p className={styles['no-content']}>{translate('users.sectionHasNoValuesDefaultMessage')}</p>
);

export const renderNameSection = (translate: TranslateT<string>): * => (
  <FormSection name="internalUserDetails" className={styles['form-section']}>
    <div className={styles['individual-field-area']}>
      <Field
        id="firstName"
        key="firstName"
        label={`${translate('users.firstName')}`}
        name="firstName"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        forceViewMode
        hideWhenViewing
      />
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="lastName"
        key="lastName"
        label={`${translate('users.lastName')}`}
        name="lastName"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        forceViewMode
        hideWhenViewing
      />
    </div>
  </FormSection>
);

export const renderACDSettings = (
  translate: TranslateT<string>,
  user: InternalUserStateEntityT,
  gotoFunc: *
): * => (
  <div>
    {gotoFunc && (
      <LinkButton
        id="supervisors_link"
        label={translate('callflows.viewCallflowContent.acdSupervisorsLink')}
        onClickAction={() => gotoFunc(user.enterpriseId, user.id)}
      />
    )}
  </div>
);

export const renderVoiceMailSettings = (
  translate: TranslateT<string>,
  voicemailSettings: ?VoiceMailEntityT
): * =>
  !!voicemailSettings && (
    <FormSection name="internalUserDetails" className={styles['form-section']}>
      <Field
        id="voicemailSettings"
        key="voicemailSettings"
        label={`${translate('users.voiceMailAudio')}`}
        name="voicemailSettings"
        translate={translate}
        voicemailSettings={voicemailSettings}
        component={UserVoiceMailAudioField}
        wrapperStyle={styles['disabled-field']}
        fieldEditingDisabled
      />
    </FormSection>
  );

export const renderLockedSection = (user: InternalUserEntityT, handleCloseView: () => void): * =>
  user.locked ? <UserLocked user={user} handleCloseView={handleCloseView} /> : <div />;

export const renderAvailabilitySection = (
  translate: TranslateT<string>,
  personalAvailability: *
): * => (
  <FormSection name="internalUserDetails.personalAvailability" className={styles['form-section']}>
    {!hasValues(personalAvailability) && emptySection(translate)}
    <Field
      id="availability"
      key="availability"
      label={`${translate('users.personalAvailability.availability')}`}
      name="availability"
      component={FormInput}
      fieldEditingDisabled
      forceViewMode
      isSectionInEditMode={false}
    />
    <Field
      id="customPresenceState"
      key="customPresenceState"
      label={`${translate('users.personalAvailability.customPresenceState')}`}
      name="customPresenceState"
      fieldEditingDisabled
      forceViewMode
      component={FormInput}
    />
  </FormSection>
);

const hideField = (
  translate: TranslateT<string>,
  fieldName: string,
  alwaysEdit: boolean,
  fieldValue: *,
  protectedFields: ?(string[]),
  spName: string,
  morePadding: ?boolean,
  forceShow: ?boolean
) => {
  return alwaysEdit ? (
    <div
      className={classnames(styles['hide-field'], {
        [styles['hide-field-more-padding']]: morePadding
      })}
    >
      <Field
        id={fieldName}
        key={fieldName}
        name={fieldName}
        component={CheckboxField}
        checked={protectedFields && protectedFields.includes(spName)}
      >
        {translate('users.personalDetails.hide')}
      </Field>
    </div>
  ) : (
    ((fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) ||
      (!Array.isArray(fieldValue) && fieldValue) ||
      forceShow) &&
      protectedFields &&
      protectedFields.includes(spName) && (
        <div className={styles['hide-area']}>
          <LockIcon className={styles['lock-icon']} />
        </div>
      )
  );
};

export const renderPersonalDetailsSection = (
  translate: TranslateT<string>,
  personalDetails: *,
  locations: *,
  currentValues: *,
  validateNickname: string => ?string,
  validateTitle: string => ?string,
  validateMobileNumber: string => ?string,
  validateEmail: string => ?string,
  validateOfficeReferenceID: string => ?string,
  alwaysEdit: boolean,
  user: InternalUserStateEntityT,
  updateDropdownValue: *
): * => (
  <FormSection name="internalUserDetails.personalDetails" className={styles['form-section']}>
    {currentValues &&
      !hasValues(currentValues.internalUserDetails.personalDetails) &&
      emptySection(translate)}
    <div className={styles['individual-field-area']}>
      <Field
        id="plmnNumbers"
        label={translate('users.personalDetails.plmnNumbersLabel')}
        name="plmnNumbers"
        type="tel"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        className={styles['input-field']}
        fieldEditingDisabled
      />
      {hideField(
        translate,
        'hidePlmnNumbers',
        alwaysEdit,
        user.plmnNumbers && user.plmnNumbers.length > 0,
        user.protectedFields,
        PLMN_NUMBERS_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="pstnNumbers-phone-0"
        label={translate('users.personalDetails.pstnNumbersLabel')}
        name="pstnNumbers"
        className={styles['input-field']}
        type="tel"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        fieldEditingDisabled
      />
      {hideField(
        translate,
        'hidePstnNumbers',
        alwaysEdit,
        user.pstnNumbers && user.pstnNumbers.length > 0,
        user.protectedFields,
        PSTN_NUMBERS_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="addressNumber"
        key="addressNumber"
        label={`${translate('users.personalDetails.shortNumberLabel')}`}
        name="addressNumber"
        type="tel"
        component={FormInput}
        wrapperStyle={styles['input-field']}
        fieldEditingDisabled
      />
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="emails"
        key="emails"
        className={styles['input-field']}
        label={`${translate('users.personalDetails.emails')}`}
        name="emails"
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateEmail}
        maxlength={EMAILS_MAX_LENGTH}
        showLength
        removeCharactersFromMaxLength={/ /g}
      />
      {hideField(
        translate,
        'hideEmails',
        alwaysEdit,
        user.emails,
        user.userProtectedFields,
        EMAILS_SP
      )}
    </div>

    <div className={styles['individual-field-area']}>
      <Field
        id="title"
        key="title"
        label={`${translate('users.personalDetails.title')}`}
        name="title"
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateTitle}
        maxlength={TITLE_MAX_LENGTH}
        showLength
      />
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="nickName"
        key="nickName"
        className={styles['input-field']}
        label={`${translate('users.personalDetails.nickName')}`}
        name="nickName"
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateNickname}
        maxlength={NICKNAME_MAX_LENGTH}
        showLength
      />
      {hideField(
        translate,
        'hideNickname',
        alwaysEdit,
        user.nickName,
        user.userProtectedFields,
        NICKNAME_SP
      )}
    </div>

    <div className={styles['individual-field-area']}>
      <Field
        id="mobileNumber"
        key="mobileNumber"
        label={`${translate('users.personalDetails.mobileNumberLabel')}`}
        name="mobileNumber"
        className={styles['input-field']}
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateMobileNumber}
        maxlength={PHONE_MAX_LENGTH}
      />
      {hideField(
        translate,
        'hideMobileNumber',
        alwaysEdit,
        user.mobileNumber,
        user.userProtectedFields,
        MOBILE_NUMBERS_SP
      )}
    </div>

    <div className={styles['individual-field-area']}>
      <Field
        id="homeNumber"
        key="homeNumber"
        label={`${translate('users.personalDetails.homeNumberLabel')}`}
        name="homeNumber"
        className={styles['input-field']}
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateMobileNumber}
        maxlength={PHONE_MAX_LENGTH}
      />
      {hideField(
        translate,
        'hideHomeNumber',
        alwaysEdit,
        user.homeNumber,
        user.userProtectedFields,
        HOME_NUMBERS_SP
      )}
    </div>

    <div className={styles['individual-field-area']}>
      <Field
        key="professionalPostalAddress"
        id="professionalPostalAddress"
        label={`${translate('users.personalDetails.professionalPostalAddress')}`}
        name="professionalPostalAddress"
        component={FormSite}
        wrapperStyle={styles['static-dropdown']}
        className={styles['input-field']}
        locations={locations}
        options={R.prepend(
          {
            value: NO_VALUE_FIELD_ID,
            label: translate('users.personalDetails.noProfessionalPostalAddress')
          },
          R.map(location => ({ value: location.name, label: location.name }), locations || [])
        )}
        updateDropdownValue={option =>
          updateDropdownValue(
            'internalUserDetails.personalDetails.professionalPostalAddress',
            option
          )
        }
      />
      {hideField(
        translate,
        'hideProfessionalPostalAddress',
        alwaysEdit,
        user.professionalPostalAddress,
        user.userProtectedFields,
        PROFESSIONAL_POSTAL_ADDRESS_SP
      )}
    </div>

    <div className={styles['individual-field-area']}>
      <Field
        id="officeReferenceID"
        key="officeReferenceID"
        label={`${translate('users.personalDetails.officeReferenceID')}`}
        name="officeReferenceID"
        component={FormInput}
        wrapperStyle={styles['input-field']}
        validate={validateOfficeReferenceID}
        maxlength={OFFICE_REFERENCE_ID_MAX_LENGTH}
        showLength
      />
    </div>
  </FormSection>
);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
export const renderListEdit = (
  selectedItems: *[],
  isSectionInEditMode: boolean,
  target: string,
  removeUserFromList: *,
  showUser: *,
  buttonText: string
) => {
  if (isSectionInEditMode) {
    return (
      <ListEdit
        selectedItems={selectedItems}
        renderListEditItem={(item: UserListElementT) => (
          <div className={classnames(styles['list-edit-item'])} id={item.id} key={item.id}>
            <div id={`${item.id}-label`} className={classnames(styles['list-edit-item--label'])}>
              {item.userName}
              <span className={styles['list-edit-item--id']}>{` (${item.addressNumber})`}</span>
            </div>
            <div
              id={`${item.id}-remove`}
              className={styles['remove-button']}
              onClick={() => removeUserFromList(item.addressNumber, target)}
            >
              {buttonText}
            </div>
          </div>
        )}
      />
    );
  }
  return (
    <ListEdit
      selectedItems={selectedItems}
      renderListEditItem={(item: UserListElementT) => (
        <div className={classnames(styles['list-view-item'])} id={item.id} key={item.id}>
          <div
            id={`${item.id}-label`}
            className={classnames(styles['list-view-item--label'])}
            onClick={() => showUser(item.id)}
          >
            {item.userName}
          </div>
        </div>
      )}
    />
  );
};

/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
export const renderResultItem = (
  id: string,
  index: number,
  item: ResultItemT,
  type: string,
  selected: boolean,
  onSelect: *
) => (
  <li key={`${id}_${index}`}>
    <ResultItem
      result={{ index, label: item.label, addressNumber: item.addressNumber }}
      selected={selected}
      onSelect={obj => onSelect(obj, type)}
    />
  </li>
);

export const renderDepartmentAndColleaguesSection = (
  translate: TranslateT<string>,
  departmentIdTree: IdTreeT,
  showDepartment: *,
  selectedSuperiors: UserListElementT[],
  foundSuperiors: InternalUserEntityT[],
  selectedAssistants: UserListElementT[],
  foundAssistants: InternalUserEntityT[],
  selectedSubstitutes: UserListElementT[],
  foundSubstitutes: InternalUserEntityT[],
  handleSearchInputChanged: *,
  handleAddToSelectedList: *,
  changeFormValue: *,
  showUser: *,
  superiorsInput: { current: null | HTMLInputElement },
  assistantsInput: { current: null | HTMLInputElement },
  substitutesInput: { current: null | HTMLInputElement },
  removeUserFromList: *,
  currentValues: *,
  alwaysEdit: boolean,
  user: InternalUserStateEntityT
): * => (
  <FormSection
    name="internalUserDetails.departmentAndColleagues"
    className={styles['form-section']}
  >
    {(currentValues &&
      !hasValues(
        R.omit(
          ['superiors', 'assistants', 'substitutes'],
          currentValues.internalUserDetails.departmentAndColleagues
        )
      )) ||
      selectedSuperiors.length === 0 ||
      selectedAssistants.length === 0 ||
      (selectedSubstitutes.length === 0 && emptySection(translate))}
    <div className={styles['individual-field-area']}>
      <Field
        id="department"
        key="department"
        label={`${translate('users.departmentAndColleagues.department')}`}
        name="department"
        options={departmentIdTree}
        component={FormIdTreeCombobox}
        wrapperStyle={styles['static-dropdown']}
        viewModeOnClick={showDepartment}
        emptySelection={{
          key: translate('users.departmentAndColleagues.noDepartmentOption'),
          value: null
        }}
        noDepartmentText={translate('users.departmentAndColleagues.noDepartmentOption')}
        hasEmptyOption
      />
      {hideField(
        translate,
        'hideLocation',
        alwaysEdit,
        user.department,
        user.userProtectedFields,
        LOCATION_SP,
        false,
        true
      )}
    </div>
    <div
      className={classnames(styles['individual-field-area'], {
        [styles['individual-field-area--view']]: !alwaysEdit
      })}
    >
      <Search
        id="superiors"
        label={translate('users.departmentAndColleagues.superiors', {
          infoText: ''
        })}
        inputLabel={translate('users.departmentAndColleagues.superiors', {
          infoText: translate('users.departmentAndColleagues.infoText')
        })}
        searchInputChanged={searchTerm =>
          handleSearchInputChanged(
            searchTerm,
            'internalUserDetails.departmentAndColleagues.superiors'
          )
        }
        searchInputContainerClassName={styles['search-field-area']}
        isLoading={false}
        selectedItems={selectedSuperiors}
        searchResults={foundSuperiors}
        searchClassName={classnames(styles['input-field'], {
          [styles['input-field--forceview']]: !alwaysEdit
        })}
        searchInputCleared={() => {
          handleSearchInputChanged(null);
          changeFormValue(
            'internalUserDetails',
            'internalUserDetails.departmentAndColleagues.superiors',
            ''
          );
        }}
        renderSelectedList={isSectionInEditMode =>
          renderListEdit(
            selectedSuperiors,
            isSectionInEditMode || false,
            'superiors',
            removeUserFromList,
            showUser,
            translate('users.remove')
          )
        }
        renderResultItem={(result: InternalUserEntityT, index: ?number, selected: boolean) => {
          const { id } = result;
          const item = {
            index: index || 0,
            label: result.userName || '',
            addressNumber: result.addressNumber
          };
          return renderResultItem(
            id,
            index || 0,
            item,
            'departmentAndColleagues.superiors',
            selected,
            handleAddToSelectedList
          );
        }}
        resultsClassName={styles['search-results']}
        handleSelectResult={selectedResult =>
          handleAddToSelectedList(null, 'departmentAndColleagues.superiors', selectedResult)
        }
        inputFieldRef={superiorsInput}
      />
      {hideField(
        translate,
        'hideSuperiors',
        alwaysEdit,
        user.superiors,
        user.protectedFields,
        SUPERIORS_SP
      )}
    </div>
    <div
      className={classnames(styles['individual-field-area'], {
        [styles['individual-field-area--view']]: !alwaysEdit
      })}
    >
      <Search
        id="assistants"
        label={translate('users.departmentAndColleagues.assistants', {
          infoText: ''
        })}
        inputLabel={translate('users.departmentAndColleagues.assistants', {
          infoText: translate('users.departmentAndColleagues.infoText')
        })}
        searchInputChanged={searchTerm =>
          handleSearchInputChanged(
            searchTerm,
            'internalUserDetails.departmentAndColleagues.assistants'
          )
        }
        isLoading={false}
        selectedItems={selectedAssistants}
        searchResults={foundAssistants}
        searchClassName={classnames(styles['input-field'], {
          [styles['input-field--forceview']]: !alwaysEdit
        })}
        searchInputContainerClassName={styles['search-field-area']}
        searchInputCleared={() => {
          handleSearchInputChanged(null);
          changeFormValue(
            'internalUserDetails',
            'internalUserDetails.departmentAndColleagues.assistants',
            ''
          );
        }}
        renderSelectedList={isSectionInEditMode =>
          renderListEdit(
            selectedAssistants,
            isSectionInEditMode || false,
            'assistants',
            removeUserFromList,
            showUser,
            translate('users.remove')
          )
        }
        renderResultItem={(result: InternalUserEntityT, index: number, selected: boolean) => {
          const { id } = result;
          const item = {
            index,
            label: result.userName || '',
            addressNumber: result.addressNumber
          };
          return renderResultItem(
            id,
            index,
            item,
            'departmentAndColleagues.assistants',
            selected,
            handleAddToSelectedList
          );
        }}
        resultsClassName={styles['search-results']}
        handleSelectResult={selectedResult =>
          handleAddToSelectedList(null, 'departmentAndColleagues.assistants', selectedResult)
        }
        inputFieldRef={assistantsInput}
      />
      {hideField(
        translate,
        'hideAssistants',
        alwaysEdit,
        user.assistants,
        user.protectedFields,
        ASSISTANTS_SP
      )}
    </div>
    <div
      className={classnames(styles['individual-field-area'], {
        [styles['individual-field-area--view']]: !alwaysEdit
      })}
    >
      <Search
        id="substitutes"
        label={translate('users.departmentAndColleagues.substitutes', {
          infoText: ''
        })}
        inputLabel={translate('users.departmentAndColleagues.substitutes', {
          infoText: translate('users.departmentAndColleagues.infoText')
        })}
        searchInputChanged={searchTerm =>
          handleSearchInputChanged(
            searchTerm,
            'internalUserDetails.departmentAndColleagues.substitutes'
          )
        }
        isLoading={false}
        selectedItems={selectedSubstitutes}
        searchResults={foundSubstitutes}
        searchInputContainerClassName={styles['search-field-area']}
        searchInputCleared={() => {
          handleSearchInputChanged(null);
          changeFormValue(
            'internalUserDetails',
            'internalUserDetails.departmentAndColleagues.substitutes',
            ''
          );
        }}
        searchClassName={classnames(styles['input-field'], {
          [styles['input-field--forceview']]: !alwaysEdit
        })}
        renderSelectedList={isSectionInEditMode =>
          renderListEdit(
            selectedSubstitutes,
            isSectionInEditMode || false,
            'substitutes',
            removeUserFromList,
            showUser,
            translate('users.remove')
          )
        }
        renderResultItem={(result: InternalUserEntityT, index: number, selected: boolean) => {
          const { id } = result;
          const item = {
            index,
            label: result.userName || '',
            addressNumber: result.addressNumber
          };
          return renderResultItem(
            id,
            index,
            item,
            'departmentAndColleagues.substitutes',
            selected,
            handleAddToSelectedList
          );
        }}
        resultsClassName={styles['search-results']}
        handleSelectResult={selectedResult =>
          handleAddToSelectedList(null, 'departmentAndColleagues.substitutes', selectedResult)
        }
        inputFieldRef={substitutesInput}
      />
      {hideField(
        translate,
        'hideSubstitutes',
        alwaysEdit,
        user.substitutes,
        user.protectedFields,
        SUBSTITUTES_SP
      )}
    </div>
  </FormSection>
);

export const renderAdditionalInformationSection = (
  translate: TranslateT<string>,
  currentValues: *,
  validateAdditionalExplanations: string => ?string,
  validateAdditionalInfo: string => ?string,
  validateContactInformation: string => ?string,
  validateCorporateUserId: string => ?string,
  validateCostCenter: string => ?string,
  validateAccessCtrlSystemPersonId: string => ?string,
  validateTagNames: string => ?string,
  validateDuties: string => ?string,
  changeFormValue: *,
  alwaysEdit: boolean,
  user: InternalUserStateEntityT
): * => (
  <FormSection name="internalUserDetails.additionalInformation" className={styles['form-section']}>
    {currentValues &&
      !hasValues(currentValues.internalUserDetails.additionalInformation) &&
      emptySection(translate)}
    <div className={styles['individual-field-area']}>
      <Field
        id="additionalExplanations"
        key="additionalExplanations"
        label={translate('users.additionalInformation.additionalExplanations')}
        name="additionalExplanations"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        validate={validateAdditionalExplanations}
        maxlength={ADDITIONAL_EXPLANATIONS_MAX_LENGTH}
        showLength
        type="textarea"
        minrows="5"
        maxrows="5"
      />
      {hideField(
        translate,
        'hideAdditionalExplanations',
        alwaysEdit,
        user.additionalExplanations,
        user.userProtectedFields,
        ADDITIONAL_EXPLANATIONS_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="additionalInfo"
        key="additionalInfo"
        label={translate('users.additionalInformation.additionalInfo')}
        name="additionalInfo"
        wrapperStyle={styles['input-field']}
        component={FormInput}
        validate={validateAdditionalInfo}
        maxlength={ADDITIONAL_INFO_MAX_LENGTH}
        showLength
        type="textarea"
        minrows="5"
        maxrows="5"
      />
      {hideField(
        translate,
        'hideAdditionalInfo',
        alwaysEdit,
        user.additionalInfo,
        user.userProtectedFields,
        ADDITIONAL_INFO_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="contactInformation"
        key="contactInformation"
        wrapperStyle={styles['input-field']}
        label={translate('users.additionalInformation.contactInformation')}
        name="contactInformation"
        component={FormInput}
        validate={validateContactInformation}
        maxlength={CONTACT_INFORMATION_MAX_LENGTH}
        showLength
        type="textarea"
        minrows="5"
        maxrows="5"
      />
      {hideField(
        translate,
        'hideContactInformation',
        alwaysEdit,
        user.contactInformation,
        user.userProtectedFields,
        CONTACT_INFORMATION_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="corporateUserId"
        key="corporateUserId"
        label={translate('users.additionalInformation.corporateUserId')}
        name="corporateUserId"
        className={styles['input-field']}
        wrapperStyle={styles['input-field']}
        component={FormInput}
        validate={validateCorporateUserId}
        maxlength={CORPORATE_USER_ID_MAX_LENGTH}
        showLength
      />
      {hideField(
        translate,
        'hideCorporateUserId',
        alwaysEdit,
        user.corporateUserId,
        user.userProtectedFields,
        CORPORATE_USER_ID_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="costCenter"
        key="costCenter"
        wrapperStyle={styles['input-field']}
        label={translate('users.additionalInformation.costCenter')}
        name="costCenter"
        className={styles['input-field']}
        component={FormInput}
        validate={validateCostCenter}
        maxlength={COST_CENTER_MAX_LENGTH}
        showLength
      />
      {hideField(
        translate,
        'hideCostCenter',
        alwaysEdit,
        user.costCenter,
        user.userProtectedFields,
        COST_CENTER_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="accessCtrlSystemPersonId"
        key="accessCtrlSystemPersonId"
        wrapperStyle={styles['input-field']}
        label={translate('users.additionalInformation.accessCtrlSystemPersonId')}
        name="accessCtrlSystemPersonId"
        component={FormInput}
        className={styles['input-field']}
        validate={validateAccessCtrlSystemPersonId}
        maxlength={ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH}
        showLength
      />
      {hideField(
        translate,
        'hideAccessCtrlSystemPersonId',
        alwaysEdit,
        user.accessCtrlSystemPersonId,
        user.userProtectedFields,
        ACCESS_CTRL_SYSTEM_PERSON_ID_SP
      )}
    </div>
    <div className={classnames(styles['individual-field-area'])}>
      <Field
        id="duties"
        key="duties"
        label={translate('users.departmentAndColleagues.duties')}
        name="duties"
        component={(props: *): Element<'div'> => {
          const mapIndexed = R.addIndex(R.map);
          const keywords = R.filter(
            entry => !!entry.text,
            mapIndexed(
              (tag, index) => ({ id: `${tag}-${index}`, text: tag }),
              (props.input.value || '').split(',')
            ) || []
          );
          return !props.isSectionInEditMode ? (
            // $FlowFixMe
            <FormInput
              wrapperStyle={styles['input-field']}
              {...{
                ...props,
                input: {
                  ...props.input,
                  value: props.input.value && props.input.value.split(',').join(', ')
                }
              }}
            />
          ) : (
            <div className={styles['input-field']}>
              <label className="ds-input--labelarea-label">
                {translate('users.departmentAndColleagues.duties')}
              </label>
              <KeywordInput
                placeholder={translate('users.departmentAndColleagues.duties')}
                initialValues={keywords}
                className={styles['input-field']}
                maxLength={DUTIES_MAX_LENGTH}
                onChange={keywordList => {
                  changeFormValue(
                    'internalUserDetails',
                    'internalUserDetails.additionalInformation.duties',
                    R.join(
                      ',',
                      keywordList.map(entry => entry.text)
                    )
                  );
                }}
              />
            </div>
          );
        }}
        validate={validateDuties}
      />
      {hideField(
        translate,
        'hideDuties',
        alwaysEdit,
        user.duties,
        user.userProtectedFields,
        DUTIES_SP
      )}
    </div>
    <div className={styles['individual-field-area']}>
      <Field
        id="tagNames"
        key="tagNames"
        label={translate('users.additionalInformation.tagNames')}
        name="tagNames"
        component={(props: *): Element<'div'> => {
          const mapIndexed = R.addIndex(R.map);
          const keywords = R.filter(
            entry => !!entry.text,
            mapIndexed(
              (tag, index) => ({ id: `${tag}-${index}`, text: tag }),
              (props.input.value || '').split(',')
            ) || []
          );
          return !props.isSectionInEditMode ? (
            // $FlowFixMe
            <FormInput
              {...{
                ...props,
                input: {
                  ...props.input,
                  value: props.input.value && props.input.value.split(',').join(', ')
                }
              }}
            />
          ) : (
            <div className={styles['input-field']}>
              <label className="ds-input--labelarea-label">
                {translate('users.additionalInformation.tagNamesLabel')}
              </label>
              <KeywordInput
                placeholder={translate('users.additionalInformation.tagNames')}
                initialValues={keywords}
                maxLength={TAG_NAMES_MAX_LENGTH}
                maxWordLength={TAG_NAME_MAX_LENGTH}
                onChange={keywordList => {
                  changeFormValue(
                    'internalUserDetails',
                    'internalUserDetails.additionalInformation.tagNames',
                    R.join(
                      ',',
                      keywordList.map(entry => entry.text)
                    )
                  );
                }}
              />
            </div>
          );
        }}
        validate={validateTagNames}
      />
    </div>
  </FormSection>
);

export const renderPersonalCallForwardingsSection = (
  translate: TranslateT<string>,
  goToEnterpriseUserForwardings: *,
  user: InternalUserStateEntityT,
  forwardings: *,
  currentValues: *,
  alwaysEdit: boolean
): * => (
  <FormSection
    name="internalUserDetails.personalCallForwardings"
    className={classnames(styles['form-section'], {
      [styles['form-section--edit']]: alwaysEdit
    })}
  >
    {currentValues &&
      !hasValues(currentValues.internalUserDetails.personalCallForwardings) &&
      emptySection(translate)}
    <LinkButton
      id="goto-forwardings"
      onClickAction={() => goToEnterpriseUserForwardings(user.enterpriseId, user.addressNumber)}
      label={translate('users.personalCallForwardings.gotoForwardings')}
      className={styles['link-button']}
    />
    {forwardings && (
      <div
        className={classnames(styles['personal-call-forwardings'], {
          [styles['personal-call-forwardings--edit']]: alwaysEdit
        })}
      >
        {forwardings &&
          forwardings.map(forwarding => (
            <div key={`user-forwarding-${forwarding.id}`} className={styles.forwarding}>
              <ForwardingTableCell userForwarding={forwarding}>
                <Reason
                  user={user}
                  userForwarding={forwarding}
                  forceSmall
                  enterpriseId=""
                  onForwardingActivityChange={() => new Promise(() => {})}
                  onDeleteForwarding={() => {}}
                />
              </ForwardingTableCell>
              <ForwardingTableCell userForwarding={forwarding}>
                <Destination user={user} userForwarding={forwarding} forceSmall />
              </ForwardingTableCell>
            </div>
          ))}
      </div>
    )}
  </FormSection>
);

export const renderUserAccountSection = (
  translate: TranslateT<string>,
  user: InternalUserStateEntityT,
  languages: *,
  currentValues: *,
  updateDropdownValue: *
): * => {
  const selectedLanguage = R.path(
    ['internalUserDetails', 'userAccount', 'language', 'value'],
    currentValues
  );
  let combinedLanguages = languages;
  if (selectedLanguage !== undefined && languages !== undefined) {
    if (R.findIndex(R.propEq('code', selectedLanguage))(languages) <= -1) {
      combinedLanguages = R.append(
        { code: selectedLanguage, name: selectedLanguage },
        combinedLanguages
      );
    }
  }

  return (
    <Fragment>
      <FormSection name="internalUserDetails.userAccount" className={styles['form-section']}>
        {currentValues &&
          !hasValues(currentValues.internalUserDetails.userAccount) &&
          emptySection(translate)}
        <Field
          key="login"
          id="login"
          label={`${translate('users.account.login')}`}
          name="login"
          component={FormInput}
          wrapperStyle={styles['disabled-field']}
          fieldEditingDisabled
        />
        <Field
          key="language"
          id="language"
          label={`${translate('users.account.language')}`}
          name="language"
          component={FormInput}
          options={R.map(
            language => ({ value: language.code, label: language.name }),
            combinedLanguages || []
          )}
          updateDropdownValue={option =>
            updateDropdownValue('internalUserDetails.userAccount.language', option)
          }
        />
      </FormSection>
    </Fragment>
  );
};

const Information = ({ translate, locked }: { translate: TranslateT<>, locked: boolean }) => (
  <Fragment>
    <div id="reset-title" className={styles['reset-title']}>
      {translate('users.account.passwordReset.title')}
    </div>
    <div id="reset-info" className={styles['reset-info']}>
      {translate('users.account.passwordReset.info')}
    </div>
    {locked && (
      <div id="reset-info" className={styles['reset-info']}>
        {translate('users.account.passwordReset.locked')}
      </div>
    )}
  </Fragment>
);

export const renderPasswordResetSection = (
  translate: TranslateT<string>,
  successIndicatorToggled: boolean,
  isSmsSelected: boolean,
  isEmailSelected: boolean,
  isUpdating: boolean,
  hasResetError: boolean,
  buttonClicked: boolean,
  hasSelectedMethodFieldError: boolean,
  selectedMethod: 'email' | 'phoneNumber' | '',
  handleSmsClick: *,
  handleEmailClick: *,
  handleChangePhoneNumber: (SyntheticInputEvent<HTMLInputElement>) => void,
  handleChangeEmail: (SyntheticInputEvent<HTMLInputElement>) => void,
  phoneNumber: string,
  email: string,
  locked: boolean,
  handleSendClick: (
    event?: SyntheticEvent<HTMLButtonElement>,
    hasSelectedMethodFieldError: boolean
  ) => void,
  handleCancelClick: () => void
): * => {
  const validNumber = isSmsSelected && phoneNumber;
  const validEmail = isEmailSelected && email;
  return (
    <FormSection
      name="internalUserDetails.passwordReset"
      className={classnames(styles['form-section'], styles['form-section--last'])}
    >
      {!successIndicatorToggled ? (
        <Fragment>
          <Information translate={translate} locked={locked} />
          <ContactSelector
            isSmsSelected={isSmsSelected}
            isEmailSelected={isEmailSelected}
            handleSmsClick={handleSmsClick}
            handleEmailClick={handleEmailClick}
            handleChangePhoneNumber={handleChangePhoneNumber}
            handleChangeEmail={handleChangeEmail}
            phoneNumber={phoneNumber}
            email={email}
          />
          {buttonClicked && (hasResetError || hasSelectedMethodFieldError) && (
            <div id="passwordReset-error" className={classnames(styles['error-message'])}>
              {translate(
                hasSelectedMethodFieldError
                  ? 'users.account.passwordReset.checkInput'
                  : 'users.account.passwordReset.failed'
              )}
            </div>
          )}
          <div className={styles['send-reset-button-container']}>
            <CancelButton
              id="modal-cancel-button"
              label={translate('users.personalDetails.cancel')}
              onClickAction={() => handleCancelClick()}
            />
            <ActionButton
              id="send-reset-message-button"
              className={classnames(styles['send-reset-button'], 'ea-button--lightblue', {
                [styles['send-reset-button-error']]: hasResetError
              })}
              disabled={isUpdating || selectedMethod === '' || !(validNumber || validEmail)}
              loading={isUpdating || buttonClicked}
              label={translate('users.account.passwordReset.send')}
              onClickAction={event => handleSendClick(event, hasSelectedMethodFieldError)}
            />
          </div>
        </Fragment>
      ) : (
        <div id="passwordReset-notification" className={styles.notification}>
          {!hasResetError && (
            <IconTickRegular color="green-600" className={styles['notification-icon']} />
          )}
          {hasResetError && (
            <IconWarningRegular color="red-600" className={styles['notification-icon--error']} />
          )}
          <Notifications
            tags={['internal-user-password-deleted']}
            render={({ message, keyId }) => (
              <div
                className={classnames('ea-notification', styles['notification-text'])}
                key={keyId}
                role="status"
              >
                {message}
              </div>
            )}
          />
        </div>
      )}
    </FormSection>
  );
};

export const renderUploadAvatarDialog = (
  translate: TranslateT<string>,
  uploading: boolean,
  disabled: boolean,
  onCloseAndCancel: () => void,
  onSubmit: () => Promise<void>,
  onDelete: () => Promise<void>,
  onFileChange: string => void,
  initialImageUrl: ?string
): * => {
  return (
    <UploadDialog
      title={translate('users.uploadAvatarDialog.title')}
      confirmLabel={translate('users.uploadAvatarDialog.confirm')}
      cancelLabel={translate('users.uploadAvatarDialog.cancel')}
      disabled={disabled}
      loading={uploading}
      onCancel={onCloseAndCancel}
      onConfirm={onSubmit}
      onDelete={onDelete}
      onClose={onCloseAndCancel}
      onFileChange={onFileChange}
      initialImageUrl={initialImageUrl}
    />
  );
};
