// @flow

import React from 'react';
import IconViewOffRegular from '@design-system/component-library/src/components/Icon/lib/IconViewOffRegular';
import styles from './HiddenIndicator.module.scss';

const HiddenIndicator = () => {
  return (
    <div className={styles['contact-hidden-container']}>
      <IconViewOffRegular size="s" />
    </div>
  );
};

HiddenIndicator.defaultProps = {};

export default HiddenIndicator;
